import React from 'react';
import { Flex, message, Typography } from 'antd';
import { LockFilled } from '@ant-design/icons';
const { Title } = Typography;
interface ErrorMessageCardProps {
  message: string;
}
const ErrorMessageCard: React.FC<ErrorMessageCardProps> = ({ message }) => (
  <div
    style={{
      textAlign: 'center',
      maxWidth: '400px',
      paddingTop: '3rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '2em',
    }}
  >
    <span
      style={{
        width: '60px',
        height: '60px',
        margin: 'auto',
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        padding: '10px',
        background: '#F0E2D5',
      }}
    >
      <LockFilled />
    </span>
    <p>{message}</p>
  </div>
);

export default ErrorMessageCard;
