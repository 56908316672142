import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge, message } from 'antd';
import ReactPlayer from 'react-player';
import ArticleTitle from './ArticleTitle';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
const { Title, Text, Paragraph } = Typography;

interface VideoCardProps {
  id: string;
  playingVideoId: string | null;
  title: string;
  description: boolean;
  date: string;
  videoURL: string;
  onVideoPlay: (isPlaying: string | null) => any;
}

const VideoCard: React.FC<VideoCardProps> = ({
  id,
  playingVideoId,
  title,
  date,
  videoURL,
  onVideoPlay,
}) => {
  useEffect(() => {
    return () => {
      onVideoPlay(null); // reset on page change
    };
  }, []);
  return (
    <>
      <article
        style={{
          background: 'none',
          maxWidth: '1000px',
          marginBottom: '15px',
        }}
      >
        <ArticleTitle
          title={`${title} | ${new Date(date).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'long',
          })}`}
        />
        <div>
          <VideoPlayer
            id={id}
            videoURL={videoURL}
            playingVideoId={playingVideoId}
            onVideoPlay={(videoId: string | null) => onVideoPlay(videoId)}
          />
        </div>
      </article>
    </>
  );
};

export default VideoCard;
