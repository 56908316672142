import React from 'react';
import { Pie } from '@ant-design/charts';
import { PieChart } from 'react-minimal-pie-chart';



const DoughnutChart = ({ data }: any) => {
  const hasData = data.find((d:any) => d.value !== 0);
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    contentHeight: '50px',
    label: {
      //type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-active' }],
  };
  return (hasData) ?  <PieChart
    animationDuration={500}
    animationEasing="ease-out"
    center={[80, 80]}
    data={data}
    label={(data:any) => `${Math.round(data.dataEntry.percentage)}%`}
    labelPosition={110}
    lengthAngle={360}
    lineWidth={50}
    paddingAngle={1}
    radius={50}
    startAngle={0}
    viewBoxSize={[160, 160]}
    background= '#fff'
    
    segmentsStyle={{
      fill: 'fff'
    }}
    labelStyle={{
      fontSize: "10px",
    }}
  /> : <PieChart
        animationDuration={500}
        animationEasing="ease-out"
        center={[80, 80]}
        data={[{ title: 'No Data', value: 100, color: "#f1f1f1" }]}
        label={(data:any) => `No Data`}
        labelPosition={0}
        lengthAngle={360}
        lineWidth={50}
        radius={50}
        startAngle={0}
        viewBoxSize={[160, 160]}
        background= '#fff'
        
        segmentsStyle={{
          fill: 'fff'
        }}
        labelStyle={{
          fontSize: "10px",
        }}
      />;
};

export default DoughnutChart;
