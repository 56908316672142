// CourseCard.js
import React from 'react';
import { Card, Typography } from 'antd';
import { ReactComponent as PlayIcon } from '../../play-icon.svg';
const { Paragraph, Title } = Typography;
const { Meta } = Card;

const CourseCard = ({
  title,
  imageUrl,
  count,
  completionPercentage,
  onPlayButtonClick,
  cardType,
  module,
}: any) => {
  return (
    <div className="course-card" onClick={() => onPlayButtonClick(module)}>
      <div className="image-container">
        <img className="cover-image" alt="example" src={imageUrl} />
      </div>

      <div className="card-footer">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '18px', fontWeight: 600 }}>{count}</div>
            <div
              style={{
                fontSize: '12px',
                fontWeight: 500,
                color: 'gray',
                textTransform: 'capitalize',
              }}
            >
              {cardType}
            </div>
          </div>
          <div style={{ textAlign: 'center', minWidth: '60px' }}>
            <div
              style={{
                fontSize: `${cardType === 'video' ? '14px' : '18px'}`,
                fontWeight: 600,
              }}
            >
              {completionPercentage}%
            </div>
            <div style={{ fontSize: '12px', color: 'gray', fontWeight: 500 }}>
              {cardType === 'video' ? '' : 'Complete'}
            </div>
          </div>
        </div>
      </div>
      {cardType === 'video' ? (
        <div className="play-btn">
          <PlayIcon className="custom-icon hero-icon-18" />
        </div>
      ) : null}

      <div className="cover-title">
        <Title className="title" level={4} ellipsis={{ rows: 3 }}>
          {title}
        </Title>
      </div>
    </div>
  );
};

export default CourseCard;
