import { Typography } from 'antd';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import SenderAvatar from '../../components/Chat/SenderAvatar';
interface DetailedMessage {
  isBotMessage: boolean;
  message: any;
}
const DetailedMessage: React.FC<DetailedMessage> = ({
  isBotMessage,
  message,
}) => {
  return (
    <>
      <div className={`chat-message message-bot`}>
        <SenderAvatar isBot={false} />
        <div className="query-response">
          <b>Detailed:</b> {message.question}
        </div>
      </div>
      <div
        className={`chat-message ${
          isBotMessage ? 'message-bot bot' : 'message-bot'
        }`}
      >
        <SenderAvatar isBot={isBotMessage} />
        <div className="query-response">
          <Markdown className="markdown-content" rehypePlugins={[rehypeRaw]}>
            {message.message}
          </Markdown>
        </div>
      </div>
    </>
  );
};

export default DetailedMessage;
