import React, { ChangeEvent, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Layout, Typography, Empty } from 'antd';
import { getCallDepartments } from '../../services/ApiActions/bestCallAct';
import BreadcrumbContext from '../../components/Context';
import Loader from '../../components/Layout/Loader';
import PageHeading from '../../components/Layout/PageHeading';
import './bestcalls.styles.scss';
const { Text } = Typography;

const BestCallsPage = () => {
  const navigate = useNavigate();
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const location = useLocation();
  useEffect(() => {
    addBreadcrumbitem({
      breadCrumb: [
        { label: 'Spoon Feeding', url: '' },
        { label: 'Ask me anything', url: location.pathname },
      ],
      headingInfo: { title: 'Ask Me Anything', description: '' },
    });
  }, []);
  const gotToDetailPage = (gotToDetailPage: string) => {
    navigate(`/best-calls/${encodeURI(gotToDetailPage)}`);
  };
  useEffect(() => {
    setIsLoading(true);
    getCallDepartments()
      .then((response: any) => {
        const { data, status } = response;
        setDepartments(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <div className="main-content">
      <Layout.Content className="layout-content">
        <PageHeading heading="Best Calls" />
        {departments?.length <= 0 ? (
          <Empty
            style={{ marginTop: '5em' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<Typography.Text>No items found.</Typography.Text>}
          />
        ) : (
          <Row gutter={[15, 15]}>
            {departments.map((callCategorie) => {
              return (
                <Col
                  style={{ width: '150px' }}
                  key={callCategorie}
                  onClick={() => gotToDetailPage(callCategorie)}
                >
                  <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                    <div>
                      <img
                        src="/images/folder.svg"
                        width={100}
                        style={{ margin: 'auto' }}
                      />
                    </div>
                    <Text style={{ fontSize: '16px', fontWeight: 500 }}>
                      {callCategorie}
                    </Text>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </Layout.Content>
    </div>
  );
};

export default BestCallsPage;
