import { Progress, Flex } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import { ReactComponent as BestCallIcon } from '../../best-call-icon.svg';
interface AudioCardProps {
  callName: string;
  callDuration: number;
  callPlayedDuration?: number;
  isSelected: boolean;
  playedPercent: number;
}
const AudioCard: React.FC<AudioCardProps> = ({
  callName,
  callDuration,
  callPlayedDuration,
  isSelected,
  playedPercent,
}) => {
  const formatDuration = (sec: any) => {
    const minutes = Math.floor(sec / 60);
    const seconds = sec - minutes * 60;
    return `${Math.floor(minutes)}:${Math.floor(seconds)}`;
  };
  return (
    <div
      style={{ cursor: 'pointer' }}
      className={`audio-card ${isSelected && 'selected'}`}
    >
      <Flex gap={5} align="center">
        <div>
          <img src="/images/audio.svg" width={56} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Flex>
            <BestCallIcon className="custom-icon hero-icon-12" />
            <div
              className="ellipsis-text"
              style={{
                marginBottom: '5px',
                width: '180px',
                marginLeft: '5px',
              }}
            >
              {callName}
            </div>
          </Flex>
          <Flex gap={10} align="center">
            <PlayCircleFilled style={{ fontSize: '16px' }} />{' '}
            <span>{formatDuration(callDuration)}</span>
          </Flex>
          {callPlayedDuration ? (
            <Progress
              strokeColor="#ccc"
              showInfo={false}
              size={[180, 5]}
              success={{
                percent: playedPercent,
                strokeColor: isSelected ? '#fff' : '#001E5A',
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </Flex>
    </div>
  );
};
export default AudioCard;
