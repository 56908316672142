import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Avatar, Flex, Badge, Menu, Dropdown, message } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import UserDropdown from '../../Header/UserDropdown';
import {
  adminNotification,
  readAllAdminNotification,
} from '../../../services/ApiActions/notificationAct';
import { formatDate } from '../../../helper/UtilityMethods';
import { AdminNotification as AdminNotificationContext } from '../../Context/AdminNotificationContext';
const { Header } = Layout;

const PageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [notifications, setNotifications] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;
  const { unreadCount, setUnreadCount, recentNotifications } =
    AdminNotificationContext();
  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response: any = await adminNotification();
      setNotifications(response.data.results);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const capitalizeName = (username: string) => {
    return username
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleNotificationIcon = (notification: any) => {
    // navigate('/admin-notification');
    fetchNotifications();
  };
  const readAllNotification = async () => {
    if (unreadCount <= 0) {
      message.info(`No unread messages found`);
      return;
    }
    try {
      const readNotification: any = await readAllAdminNotification();
      if (readNotification?.status === 'success') {
        message.success(`Marked all notifications as 'read' successfully`);

        const newFilteredRecords = [...notifications];
        const updatedFilteredRecords = newFilteredRecords.map((record) => {
          record.read = true;
          return record;
        });
        setNotifications(updatedFilteredRecords);
        setUnreadCount(0);
        navigate(`${location.pathname}${location.search}`);
      } else {
        message.error(`Something went wrong`);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  const notificationCount = notifications.filter((n: any) => !n.read).length;
  const slicedNotifications = notifications.slice(0, 3);

  const notificationMenu = (
    <div
      style={{ marginLeft: '80px', marginBottom: '-35px', maxWidth: '350px' }}
    >
      <Menu
        className="notifications-menu"
        style={{ backgroundColor: ' #FBF6F3' }}
      >
        {recentNotifications && recentNotifications.length === 0 ? (
          <Menu.Item>
            <div className="notification-spacing">
              <div className="empty-notifications">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Flex justify="center" align="center" vertical={true}>
                    <BellOutlined />
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      No Notifications Yet!
                    </div>
                    <div style={{ fontSize: '18px', fontWeight: 400 }}>
                      When you get notifications, they'll show up here.
                    </div>
                  </Flex>
                </div>
              </div>
            </div>
          </Menu.Item>
        ) : (
          <div>
            <Menu.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  Notifications
                  <Badge
                    count={unreadCount}
                    offset={[18, 5]}
                    style={badgeStyle}
                  ></Badge>
                </div>

                <div
                  className="pointer"
                  style={{
                    color: '#1D322D99',
                    fontSize: '14px',
                    marginTop: '5px',
                    fontWeight: 500,
                  }}
                  onClick={() => readAllNotification()}
                >
                  Mark all read
                </div>
              </div>
            </Menu.Item>
            <Menu.Item style={{ gap: '10px' }}>
              {recentNotifications &&
                recentNotifications.map((notification: any) => (
                  <div
                    className="menu-notification pointer"
                    key={notification.id}
                    style={{
                      backgroundColor: !notification.read ? '#F0E2D5' : '',
                    }}
                  >
                    {!notification.read && (
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: '-5px',
                            right: 0,
                          }}
                        >
                          <Badge />
                        </div>
                      </div>
                    )}
                    <div style={{ marginBottom: '5px' }}>
                      {notification.message}
                    </div>
                    <div style={{ fontSize: '12px', color: '#888' }}>
                      {formatDate(notification.createdAt)}
                    </div>
                  </div>
                ))}
            </Menu.Item>
            <Menu.Item>
              <Link to="/admin-notification">
                <a
                  style={{
                    color: '#1D322D99',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontWeight: 500,
                  }}
                >
                  Show All
                </a>
              </Link>
            </Menu.Item>
          </div>
        )}
      </Menu>
    </div>
  );
  return (
    <Header style={headerStyle}>
      {contextHolder}
      <div>
        <Link to="/admin" style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/images/logo-admin.svg" alt="Spoon Feeding" />
        </Link>
      </div>
      <div style={flexGrowStyle}>
        <div style={flexEndStyle}>
          <Dropdown overlay={notificationMenu} trigger={['click']}>
            <Badge
              dot={unreadCount > 0}
              // count={notifications.filter((n: any) => !n.read).length}
              //count={notificationCount}
              offset={[-12, 12]}
              style={badgeStyle}
              className="pointer"
            >
              <div>
                <BellOutlined style={bellIconStyle} />
              </div>
            </Badge>
          </Dropdown>
          <UserDropdown
            username={user?.username && capitalizeName(user.username)}
          />
        </div>
      </div>
    </Header>
  );
};

const headerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F2F0E9',
  borderBottom: 'solid 1px #B4A499',
  padding: '0 15px',
  position: 'sticky',
  top: 0,
  zIndex: 10,
  // position: 'fixed',
  // zIndex: 1000,
  // width: "100%",
  //opacity: 0
};

const flexGrowStyle: React.CSSProperties = {
  flexGrow: 1,
};

const flexEndStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 16,
};

const badgeStyle: React.CSSProperties = {
  backgroundColor: 'red',
};

const bellIconStyle: React.CSSProperties = {
  fontSize: '20px',
  margin: '10px',
};

export default PageHeader;
