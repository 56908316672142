import dayjs, { Dayjs } from 'dayjs';
import api from '../api';
import {
  newsletterApi,
  notificationApi,
  researchReportApi,
} from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};

export const getResearchReport = (
  reportName: string,
  startDate?: string,
  endDate?: string,
  current?: number,
  pageSize?: number
) => {
  //?start_date=2024-04-01&end_date=2024-06-06&page=8&start_index=0

  let api = '';
  if (reportName === 'research-parameters') {
    api = `/api/custom/researchparameters`;
  } else if (reportName === 'research-reports') {
    api = `/api/custom/researchreports`;
  } else if (reportName === 'performance-report') {
    api = `/api/custom/performancereports`;
  }

  if (startDate || endDate || current || pageSize) {
    let filters = '?';
    if (startDate) {
      filters += `&start_date=${startDate}`;
    }
    if (endDate) {
      filters += `&end_date=${endDate}`;
    }
    if (current) {
      filters += `&page=${current}`;
    }
    if (pageSize) {
      filters += `&pageSize=${pageSize}`;
    }
    api += filters;
  }
  const { getResearchParameters: researchReportConfig } = researchReportApi;
  const apiConfig = { ...researchReportConfig, api: api };
  return handleApiCall(apiConfig);
};
export const getRecentUpdate = (
  updateType?: string,
  startDate?: string,
  endDate?: string,
  priority?: 'oldest' | 'newest' | 'mostrelevant',
  current?: number,
  pageSize?: number
) => {
  //?start_date=2024-04-01&end_date=2024-06-06&page=8&start_index=0

  let api = '';
  if (updateType == 'daily-trends') {
    api = `/api/informed-investorr/daily-trends`;
  } else if (updateType == 'weekly-roundup') {
    api = `/api/informed-investorr/weekly-roundup`;
  }
  if (startDate || endDate || current || pageSize || priority) {
    let filters = '?';
    if (priority) {
      filters += `&priority=${priority}`;
    }
    if (startDate) {
      filters += `&start_date=${startDate}`;
    }
    if (endDate) {
      filters += `&end_date=${endDate}`;
    }
    if (current) {
      filters += `&page=${current}`;
    }
    if (pageSize) {
      filters += `&pageSize=${pageSize}`;
    }
    api += filters;
  }
  const { getResearchParameters: researchReportConfig } = researchReportApi;
  const apiConfig = { ...researchReportConfig, api: api };
  return handleApiCall(apiConfig);
};

export const getNewsletters = (
  startDate?: string,
  endDate?: string,
  current?: number,
  pageSize?: number
) => {
  //?start_date=2024-04-01&end_date=2024-06-06&limit=8&start_index=0

  let api = '/api/custom/news-letter';

  if (startDate || endDate || current || pageSize) {
    let filters = '?';
    if (startDate) {
      filters += `&start_date=${startDate}`;
    }
    if (endDate) {
      filters += `&end_date=${endDate}`;
    }
    if (current) {
      filters += `&page=${current}`;
    }
    if (pageSize) {
      filters += `&pageSize=${pageSize}`;
    }
    api += filters;
  }
  const { getResearchParameters: researchReportConfig } = researchReportApi;
  const apiConfig = { ...researchReportConfig, api: api };
  return handleApiCall(apiConfig);
};

export const userNotification = (
  updateType?: string,
  id?: number,
  seen?: boolean,
  assessment_result?: string,
  createdAt?: string,
  updatedAt?: string,
  publishedAt?: string,
  assessment_id?: string
) => {
  let api = '/api/spoonfeed/lms/user-notification';
  if (
    id ||
    seen ||
    assessment_result ||
    createdAt ||
    updatedAt ||
    publishedAt ||
    assessment_id
  ) {
    let filters = '?';
    if (id) {
      filters += `&id=${id}`;
    }
    if (seen) {
      filters += `&seen=${seen}`;
    }
    if (assessment_result) {
      filters += `&assessment_result=${assessment_result}`;
    }
    if (createdAt) {
      filters += `&createdAt=${createdAt}`;
    }
    if (updatedAt) {
      filters += `&updatedAt=${updatedAt}`;
    }
    if (publishedAt) {
      filters += `&publishedAt=${publishedAt}`;
    }
    if (assessment_id) {
      filters += `&assessment_id=${assessment_id}`;
    }

    api += filters;
  }
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  return handleApiCall(apiConfig);
};

export const readSingleNotification = (id: number) => {
  const api = `/api/spoonfeed/lms/user-notification-read?id=${id}`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  return handleApiCall(apiConfig);
};

export const readAllNotification = () => {
  const api = `/api/spoonfeed/lms/user-all-notification-read`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  console.log(apiConfig);
  return handleApiCall(apiConfig);
};

export const getAllNotificationFilter = (read: boolean) => {
  const api = `/api/spoonfeed/lms/user-notification?read=${read}`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  console.log(apiConfig);
  return handleApiCall(apiConfig);
};

export const adminNotification = (
  updateType?: string,
  id?: number,
  seen?: boolean,
  assessment_result?: string,
  createdAt?: string,
  updatedAt?: string,
  publishedAt?: string,
  assessment_id?: string
) => {
  let api = '/api/spoonfeed/lms/admin-all-notification-read';
  const { adminNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  return handleApiCall(apiConfig);
};

export const getNotifications = (
  read?: boolean,
  current?: number,
  pageSize?: number
) => {
  let queryParams: string[] = [];

  // Add `read` parameter if it's defined
  if (read !== undefined) {
    queryParams.push(`read=${read}`);
  }

  // Add `page` parameter if it's provided
  if (current) {
    queryParams.push(`page=${current}`);
  }

  // Add `pageSize` parameter if it's provided
  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  // Join the query parameters to form the full query string
  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

  // Construct the full API URL
  const api = `/api/spoonfeed/lms/user-notification${queryString}`;

  // Get the API config for user notifications
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api };

  console.log(apiConfig);

  // Call the API
  return handleApiCall(apiConfig);
};
