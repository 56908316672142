import React from 'react';
import { Flex, DatePicker, Badge, Button } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;

export function FilterBar({
  tabs,
  selectedTab,
  onTabClick,
  onAllClick,
  rangePresets,
  defaultDateRange,
  onRangeChange,
  dateFormat,
  onPriorityChange,
  priority,
  showPriorityFilter,
  showDateRange,
  hideDateRange,
  dateFilter,
  selectedDateOption,
}: {
  tabs: { label: string; key: string }[];
  selectedTab: string;
  onTabClick: (tabId: string) => void;
  onAllClick: () => void;
  rangePresets: any;
  defaultDateRange: [Dayjs | null, Dayjs | null, undefined, undefined];
  onRangeChange: (e: any) => void;
  dateFormat: string;
  onPriorityChange?: (e: any) => void;
  priority?: string;
  showPriorityFilter?: boolean;
  dateRangePickerProps?: boolean;
  showDateRange?: boolean;
  hideDateRange?: boolean;
  dateFilter?: React.ReactNode;
  selectedDateOption?: string;
}): React.JSX.Element {
  //const dadte : [start: Dayjs, end: Dayjs] = [dayjs(),  dayjs()];
  //other-important-updates
  return (
    <div
      style={{ display: 'flex', paddingBottom: '15px', marginRight: '10px' }}
    >
      <div style={{ flexGrow: 1 }}>
        <Flex gap='small'>
          {tabs.length > 0 &&
            tabs.map((tab: any) => {
              const isSelected = tab.key == selectedTab;
              return (
                <Button
                  //size="large"
                  className={`tab-button curved-border ${
                    isSelected ? 'active' : ''
                  }`}
                  onClick={() => onTabClick(tab.key)}
                  icon={isSelected ? tab.icon_active : tab.icon}
                >
                  {tab.label}
                </Button>
              );
            })}
        </Flex>
      </div>
      <Flex gap={10} className='filters' align='center'>
        <div
          className='priority-tabs'
          style={{
            borderRadius: '8px',
            padding: showPriorityFilter ? '8px' : '0',
          }}
        >
          <Flex align='center'>
            {showPriorityFilter ? (
              <Flex align='center'>
                <span
                  className={`btn ${
                    !(
                      (selectedDateOption && selectedDateOption !== 'all') ||
                      priority
                    )
                      ? 'active'
                      : ''
                  }`}
                  onClick={onAllClick}
                >
                  All
                </span>
                <span className='seperator'>|</span>
                <span
                  className={`btn high ${
                    priority == 'mostrelevant' ? 'active' : ''
                  }`}
                  onClick={() =>
                    onPriorityChange && onPriorityChange('mostrelevant')
                  }
                >
                  {' '}
                  <Badge
                    className={`video-priority`}
                    color={'#D9534F'}
                    style={{ width: '10px', height: '10px' }}
                  />
                  Most Relevant{' '}
                </span>
                <span className='seperator'>|</span>
                <span
                  className={`btn medium ${
                    priority == 'newest' ? 'active' : ''
                  }`}
                  onClick={() => onPriorityChange && onPriorityChange('newest')}
                >
                  {' '}
                  <Badge
                    className={`video-priority`}
                    color={'#5CB85C'}
                    style={{ width: '10px', height: '10px' }}
                  />
                  Newest{' '}
                </span>
                <span className='seperator'>|</span>
                <span
                  className={`btn low ${priority == 'oldest' ? 'active' : ''}`}
                  onClick={() => onPriorityChange && onPriorityChange('oldest')}
                >
                  {' '}
                  <Badge
                    className={`video-priority`}
                    color={'#F0AD4E'}
                    style={{ width: '10px', height: '10px' }}
                  />
                  Oldest{' '}
                </span>
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
        </div>
        {showDateRange ? (
          <div>
            {dateFilter}
            {/* <RangePicker
              className={`${
                defaultDateRange[0] && defaultDateRange[1] ? "active" : ""
              }`}
              presets={rangePresets}
              size="middle"
              value={defaultDateRange}
              onChange={onRangeChange}
              format={dateFormat}
            /> */}
          </div>
        ) : (
          ' '
        )}
      </Flex>
    </div>
  );
}
