import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Breadcrumb as AntBreadcrumb, Flex, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import CourseCard from '../components/LMSComponent/CourseCard';
import {
  getAllModulesByCourseId,
  getCertificate,
} from '../services/ApiActions/lmsAct';
import Loader from '../components/Layout/Loader';
import AssesmentList from '../components/LMSComponent/AssesmentList';
import CertificateCard from '../components/LMSComponent/CertificateCard';
import PageHeading from '../components/Layout/PageHeading';

const LMSCoursePage = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { courseId } = useParams<{ courseId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState({
    courseName: '',
    modules: [],
    assessments: [],
    certificate: { is_lock: true },
  });

  useEffect(() => {
    if (courseId) {
      setIsLoading(true);
      getAllModulesByCourseId(courseId)
        .then((response: any) => {
          if (response.status === 'success') {
            sessionStorage.setItem('course_name', response.data.courseName);
            setCourseData(response.data);
            sessionStorage.setItem('courses', JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.error('Error fetching courses:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [courseId]);

  const handleModuleClick = (module: any) => {
    if (module.isLock) {
      messageApi.info('Please finish previous module to proceed');
      return;
    }
    navigate(`/lms/course/${courseId}/${module.id}/chapter`);
  };

  const handleAssesmentClick = (assesmentId: string) => {
    navigate(`/lms/course/${courseId}/assesment/${assesmentId}`);
  };
  const handleCourseClick = async (certificateId: string) => {
    try {
      const certificate: any = await getCertificate(certificateId);
      const blob = new Blob([certificate], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'certificate.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      messageApi.error('Something went wrong. Please try later.');
    }
  };

  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <div>
      {contextHolder}
      <PageHeading
        heading={courseData.courseName}
        onBackClick={() => navigate('/lms')}
      />
      <AntBreadcrumb style={{ margin: '0 0 16px' }}>
        <AntBreadcrumb.Item>
          <a href="/lms">LMS</a>
        </AntBreadcrumb.Item>
        <AntBreadcrumb.Item>{courseData.courseName}</AntBreadcrumb.Item>
      </AntBreadcrumb>
      <Typography.Title
        className="page-sub-heading"
        level={4}
        style={{ marginTop: 0, marginBottom: '10px' }}
      >
        Modules
      </Typography.Title>
      <Flex wrap gap="24px">
        {courseData.modules.map((module: any) => (
          <CourseCard
            key={module.id}
            title={module.name}
            imageUrl={module.thumbnail?.url || ''}
            count={module.chapterCount}
            cardType="video"
            completionPercentage={module.percentage.toFixed(0) || 0}
            onPlayButtonClick={(module: any) => handleModuleClick(module)}
            module={module}
          />
        ))}
      </Flex>
      <AssesmentList
        assessments={courseData.assessments}
        isLock={false}
        onAssesmentCardClick={handleAssesmentClick}
      />
      <CertificateCard
        isLock={courseData.certificate?.is_lock ? true : false}
        id={courseId || ''}
        onAssesmentCardClick={handleCourseClick}
      />
    </div>
  );
};

export default LMSCoursePage;
