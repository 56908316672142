import { ReactElement, useState } from 'react';
import { Select } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
const { Option } = Select;
interface LogoProps {
  styles?: React.CSSProperties;
  width?: number;
}
const Logo: React.FC<LogoProps> = ({ styles, width }) => {
  return (
    <img
      src="/images/logo.png"
      width={width || 200}
      height="auto"
      alt="Spoon Feeding"
      style={styles}
    />
  );
};
export default Logo;
