import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {
  toolbarPlugin,
  ToolbarSlot,
  TransformToolbarSlot,
} from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { ReactElement, useState } from 'react';
import { Input } from 'antd';
//import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Manually set the worker to use the local pdfjs-dist version

//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface PdfViewerProps {
  fileUrl: any;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl }) => {
  const [zoom, setZoom] = useState(1.0); // Zoom level in scale (1.0 means 100%)
  const minZoom = 0.5; // Set your minimum zoom level here (50%)
  const maxZoom = 2.0; // Set a maximum zoom level here (200%)
  const handleZoomOutClick = () => {
    setZoom((current) => current - 0.1);
  };
  const handleZoomInClick = () => {
    setZoom((current) => current + 0.1);
  };
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
    const { Zoom, Open } = slot;
    return {
      ...slot,
      // These slots will be empty
      Download: () => <></>,
      Open: () => <></>,
      Print: () => <></>,
    };
  };
  const renderToolbar = (Toolbar: (props: any) => ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar,
  });
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div style={{ height: 'calc(100vh - 100px)' }}>
        <Viewer
          theme="dark"
          defaultScale={1}
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  );
};

export default PdfViewer;
