import React from 'react';
import { Dropdown, Avatar, Space } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/ApiActions/authAct';
import { getUserInitialLetter } from '../../helper/UtilityMethods';

type UserDropdownProps = {
    username: string | null;
};

const UserDropdown: React.FC<UserDropdownProps> = ({ username }) => {
    const navigate = useNavigate();
    const items: MenuProps['items'] = [
        {
            key: 'logout',
            label: 'Logout',
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'logout') {
            logout();
            navigate('/login');
        }
    };

    return (
        <Dropdown menu={{ items, onClick }}>
            <Space>
            <Avatar
                      size={25}
                      shape="circle"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#FFCD29",
                        color: "#000",
                        fontWeight: "400",
                      }}
                      className="custom-icon"
                    >
                      <div>{getUserInitialLetter()}</div>
                    </Avatar>
                <span style={avatarTextStyle}>{username}</span>
            </Space>
        </Dropdown>
    );
};

const avatarTextStyle = {
    marginLeft: '5px',
};

export default UserDropdown;
