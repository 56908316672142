import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge } from 'antd';
import ReactPlayer from 'react-player';
import ItemCard from './ListCard';
import VideoCard from './VideoCard';
const { Title, Text, Paragraph } = Typography;

interface PriorityBadgeProps {
  priority: string;
}

const PriorityBadge: React.FC<PriorityBadgeProps> = ({ priority }) => {
  let badgeColor = '';
  let bgColor = '';
  let label = '';
  if (priority && priority == 'oldest') {
    label = 'Old';
    badgeColor = '#FDCF8E';
    bgColor = '#FEFFE6';
  } else if (priority && priority == 'newest') {
    label = 'New';
    badgeColor = '#5CB85C';
    bgColor = '#F6FFED';
  } else if (priority && priority == 'mostrelevant') {
    label = 'Most Relevant';
    badgeColor = '#D9534F';
    bgColor = '#FFF1F0';
  }
  return (
    <div
      style={{
        color: badgeColor,
        border: `solid 1px ${badgeColor}`,
        fontSize: '12px',
        lineHeight: '22px',
        background: `${bgColor}`,
        marginRight: '5px',
        padding: '1px 8px',
        whiteSpace: 'nowrap',
      }}
    >
      {label}
    </div>
  );
};

export default PriorityBadge;
