import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface SenderAvatarProps {
  isBot: boolean;
}

const SenderAvatar: React.FC<SenderAvatarProps> = ({ isBot }) => {
  if (isBot) {
    return (
      <Avatar
        size={36}
        shape="square"
        src={<img src="/images/logo-sm.svg" alt="Spoon Feeding" />}
        style={{ minWidth: '36px' }}
      />
    );
  }

  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;
  const userInitials = user?.username ? user.username[0] : '';

  return userInitials ? (
    <Avatar
      size="small"
      shape="circle"
      style={{
        backgroundColor: '#FFCD29',
        color: '#000',
        fontWeight: '400',
        marginTop: '12px',
        minWidth: '36px',
        minHeight: '36px',
        margin: '6px 0px',
        fontSize: '18px',
      }}
    >
      {userInitials.toUpperCase()}
    </Avatar>
  ) : (
    <Avatar
      size={30}
      shape="circle"
      style={{ border: 'solid 1px #ccc', minWidth: '36px' }}
      icon={<UserOutlined />}
    />
  );
};

export default SenderAvatar;
