import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Card, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form';
import { login, getUserRole } from '../../services/ApiActions/authAct';
import Toast from '../../services/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { forgetPassword } from '../../services/ApiActions/authAct';
import Logo from '../../components/Header/Logo';
import PageHeading from '../../components/Layout/PageHeading';
import './login.styles.scss';
const { Title, Text } = Typography;
type FieldType = {
  username?: string;
  password?: string;
  placeholder?: string;
  //remember?: boolean; // Change the type of remember field to boolean
};
interface CustomError {
  data: any;
  error: any;
}

const LoginPage: React.FC = () => {
  const [isProcesssing, setIsProcesssing] = useState(false);
  const navigate = useNavigate();
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    const { username, password } = values;
    setIsProcesssing(true);
    try {
      const res = (await login({
        identifier: username,
        password: password,
      })) as any;
      const { jwt, user } = res;

      sessionStorage.setItem('token', jwt);
      sessionStorage.setItem('user', JSON.stringify(user));
      const response: any = await getUserRole();
      const userRole = response.data.role.type;
      console.log('API Response (userRole):', userRole);

      sessionStorage.setItem('userRole', userRole || '');

      if (userRole === 'admin') {
        navigate('/admin');
      } else {
        navigate('/ask-me-anything');
      }
    } catch (e) {
      let errorMessage = 'Something went wrong';
      if (e?.error?.name === 'ValidationError') {
        errorMessage = 'Invalid username or password';
      } else if (e?.error?.message) {
        errorMessage = e.error.message;
      }

      Toast({
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setIsProcesssing(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo
  ) => {
    console.log('Failed:', errorInfo);
  };
  //remember: true
  return (
    <div className="login-bg" style={{ minHeight: '100vh' }}>
      <div className="login-overlay" style={{ minHeight: '100vh' }}>
        <div style={{ padding: '20px 24px' }}>
          <div>
            <Logo />
            <div
              style={{
                width: '400px',
                paddingTop: '6rem',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Card>
                <div style={{ marginBottom: '15px' }}>
                  <Flex gap="small" align="end" style={{ margin: '0' }}>
                    <Flex style={{ flexGrow: 1 }} align="baseline">
                      <Title
                        level={3}
                        className="heading"
                        style={{ margin: '0', fontSize: '24px' }}
                      >
                        Welcome Back!
                      </Title>
                      <img
                        className="sparkle"
                        src="/images/icons/sparkles.svg"
                        style={{ top: '-8px' }}
                      />
                    </Flex>
                  </Flex>
                  <Text type="secondary">Please enter your details</Text>
                </div>
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{}}
                  onFinish={onFinish}
                  //onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email ID!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Email ID" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter Your Password" />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: 0 }}>
                    <Flex align="flex-end" justify="flex-end">
                      {
                        //<div style={{flexGrow: '1'}}>&nbsp;</div>
                        /*<Link to={'/forgot-password'} style={{flexGrow: '1'}}>
                  Forgot password?
                  </Link>*/
                      }
                      <Button
                        loading={isProcesssing}
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: '100%',
                          marginTop: '16px',
                          border: 'none',
                          boxShadow: 'none',
                        }}
                      >
                        Login
                      </Button>
                    </Flex>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
