import { useState } from 'react';
import { Select } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
const { Option } = Select;
interface DateDropdownProps {
  onOptionChange: (vaue: string) => any;
  selectedOption?: string;
}
const DateDropdown: React.FC<DateDropdownProps> = ({
  onOptionChange,
  selectedOption = 'all',
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDropdownChange = (value: string) => {
    let startDate: string | null = null;
    let endDate: string | null = dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
    let selectedValue = 'all';

    switch (value) {
      case 'most_recent':
        startDate = dayjs().startOf('month').format('YYYY-MM-DD');
        endDate = dayjs().format('YYYY-MM-DD');
        selectedValue = 'most_recent';
        break;
      case 'last_month':
        startDate = dayjs()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
        selectedValue = 'last_month';
        break;
      case 'last_3_months':
        startDate = dayjs()
          .subtract(3, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
        selectedValue = 'last_3_months';
        break;
      case 'last_6_months':
        startDate = dayjs()
          .subtract(6, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
        selectedValue = 'last_6_months';
        break;
      case 'last_year':
        startDate = dayjs()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD');
        endDate = dayjs()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD');
        selectedValue = 'last_year';
        break;
      default:
        startDate = null;
        endDate = null;
        selectedValue = 'all';
        break;
    }

    onOptionChange(selectedValue);
    navigate({
      pathname: location.pathname,
      search:
        startDate === null || endDate === null
          ? ''
          : `?start-date=${startDate}&end-date=${endDate}`,
    });
  };
  return (
    <Select
      //size="large"
      defaultValue={selectedOption}
      style={{ width: 150 }}
      onChange={handleDropdownChange}
      value={selectedOption}
      className={
        selectedOption === 'all' ? 'date-dropdown' : 'date-dropdown active'
      }
    >
      <Option value='all'>All</Option>
      <Option value='most_recent'>Most Recent</Option>
      <Option value='last_month'>Last Month</Option>
      <Option value='last_3_months'>Last 3 Months</Option>
      <Option value='last_6_months'>Last 6 Months</Option>
      <Option value='last_year'>Last Year</Option>
    </Select>
  );
};
export default DateDropdown;
