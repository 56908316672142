import { Card, Typography } from 'antd';
import {
  ChatBubbleBottomCenterTextIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import ReactPlayer from 'react-player';

const { Title } = Typography;

interface GreetingProps {}

const Greeting: React.FC<GreetingProps> = () => {
  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;

  return (
    <div style={{ flex: '1 1 0%' }}>
      <div className={`main-chat-content`} style={{ overflowY: 'hidden' }}>
        <div
          style={{
            maxWidth: '1000px',
            marginTop: '100px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Title
            level={3}
            style={{
              marginTop: '0',
              color: '#898989',
              textAlign: 'center',
              fontSize: '35px',
              fontWeight: 500,
            }}
          >
            Hi {user?.username}!
          </Title>
          <Title
            level={2}
            className="heading"
            style={{
              marginTop: '0',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 500,
            }}
          >
            How can I help you today?
          </Title>
        </div>
      </div>
    </div>
  );
};

export default Greeting;
