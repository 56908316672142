import React, { useState } from 'react';
import { Modal, Button, Form, Input, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { login, forgetPassword } from '../../services/ApiActions/authAct';
import Toast from '../../services/ToastMessage';
import { FormProps } from 'antd/lib/form';

type FieldType = {
  username?: string;
  password?: string;
};

const ProfileModal: React.FC = () => {
  const user = JSON.parse(sessionStorage.getItem('user') || '{}');

  return (
    <div
      style={{
        width: '200px',
      }}
    >
      <p
        style={{
          fontSize: '18px',
          fontWeight: 600,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        {user.username}
      </p>
      <p
        style={{
          fontSize: '16px',
          fontWeight: 400,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        {user.designation || 'Designation not set'}
      </p>
    </div>
  );
};

export default ProfileModal;
