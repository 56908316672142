// src/context/NotificationContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { getNotifications } from '../../services/ApiActions/newsletterAct';

interface NotificationContextProps {
  unreadCount: number;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>;
  fetchNotifications: () => Promise<void>;
  recentNotifications: Notification[] | null;
  setRecentNotifications: React.Dispatch<
    React.SetStateAction<Notification[] | null>
  >;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [recentNotifications, setRecentNotifications] = useState<
    Notification[] | null
  >(null);

  const fetchNotifications = async () => {
    try {
      const response: any = await getNotifications(false);
      const recent5Notifications: any = await getNotifications(undefined, 1, 3);
      if (response.status === 'success') {
        const unread = response.data.results.length;
        setUnreadCount(unread);
        setRecentNotifications(recent5Notifications.data.results);
      } else {
        console.error(
          'Failed to fetch notifications: ',
          response.message || 'Unknown Error'
        );
      }
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        unreadCount,
        setUnreadCount,
        fetchNotifications,
        setRecentNotifications,
        recentNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
