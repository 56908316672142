import React from 'react';
import { Card, Typography, Tooltip } from 'antd';
const { Paragraph } = Typography;
const { Meta } = Card;

interface AssesmentCardPros {
  id: string;
  name: string;
  isLocked: boolean;
  isComplete?: boolean;
  onAssesmentCardClick: (assesmentId: string) => any;
  imgSrc: string;
  className?: string;
  showToolTip?: boolean;
}

const AssesmentCard = ({
  id,
  name,
  isLocked,
  isComplete,
  imgSrc,
  onAssesmentCardClick,
  className,
  showToolTip,
}: AssesmentCardPros) => {
  const paragraphContent = (
    <Paragraph
      className="title"
      style={{
        margin: 0,
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'center',
      }}
      ellipsis={{ rows: 2 }}
    >
      {name}
    </Paragraph>
  );
  return (
    <div
      className={`assessment-course-card ${className}`}
      style={{
        textAlign: 'center',
        width: '141px',
        cursor: `${!isLocked ? 'pointer' : ''}`,
      }}
      onClick={() => !isLocked && onAssesmentCardClick(id)}
    >
      <img width={55} alt="example" src={imgSrc} />
      {showToolTip ? (
        <Tooltip title={name} placement="top">
          {paragraphContent}
        </Tooltip>
      ) : (
        paragraphContent
      )}
    </div>
  );
};

export default AssesmentCard;
