import { Image } from 'antd';
interface ImageList {
  images?: any;
}
const ImageList: React.FC<ImageList> = ({ images }) => {
  const mediaWrapper = {
    padding: '5px',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px',
    border: '1px solid #f0f0f0',
  };
  return images.map((url: any) => {
    return (
      <div style={mediaWrapper}>
        <Image
          src={url}
          className="mr-8"
          style={{
            maxWidth: '100%',
            height: 'auto',
            backgroundColor: '#fff',
          }}
        />
      </div>
    );
  });
};
export default ImageList;
