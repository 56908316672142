import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Header/Logo';
const { Title, Text } = Typography;

const PasswordChangeSuccessPage: React.FC = () => {
  const navigate = useNavigate();
  //remember: true
  return (
    <div>
      <div
        style={{
          width: '500px',
          paddingTop: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Flex align="flex-start" justify="center" gap={10}>
          <Logo />
        </Flex>
        <section style={{ textAlign: 'center' }}>
          <Title
            level={3}
            className="heading"
            style={{ fontSize: '24px', fontWeight: 600 }}
          >
            Congratulations! You have successfully
            <br /> changed your password!
          </Title>
          <Title
            level={3}
            style={{
              marginTop: 0,
              fontSize: '24px',
              fontWeight: 600,
              color: '#1D322DB2',
            }}
          >
            Login Again!
          </Title>

          <Button
            type="primary"
            onClick={() => navigate('/login')}
            style={{ width: '180px', marginTop: '20px' }}
          >
            Login
          </Button>
        </section>
      </div>
    </div>
  );
};

export default PasswordChangeSuccessPage;
