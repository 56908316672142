import React, { useState, useEffect, useContext } from 'react';
import { MenuProps, Breadcrumb as AntBreadcrumb, Pagination } from 'antd';
import {
  Link,
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { getRecentUpdate } from '../services/ApiActions/newsletterAct';
import BreadcrumbContext from '../components/Context';
import ArticleLayout from '../components/Layout/ArticleLayout';
import { defaultPageSize } from '../helper/UtilityMethods';
type MenuItem = Required<MenuProps>['items'][number];
const RecentUpdatesPage = () => {
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const dateFormat = 'DD-MM-YYYY';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateType } = useParams();
  const defaultSelectedMenu = `submenu-${updateType}`;
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [tabsWithCount, setTabsWithCount] = useState([]);
  const [total, setTotal] = useState();
  const [priority, setPriority] = useState();
  const [current, setCurrent] = useState(1);
  const defaultDate =
    searchParams.get('start-date') && searchParams.get('end-date')
      ? [
          dayjs(searchParams.get('start-date')),
          dayjs(searchParams.get('end-date')),
        ]
      : [];
  const [defaultDateRange, setDefaultDateRange] = useState<any>(defaultDate);
  const [selectedTab, setSelectedTab] = useState('video');
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);
  const [records, setRecords] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      key: 'submenu-daily-trends',
      label: (
        <Link
          to={`/informed-investor-rr/daily-trends`}
          onClick={() => onLinkClick()}
        >
          Daily Trends
        </Link>
      ),
    },
    {
      key: 'submenu-weekly-roundup',
      label: (
        <Link
          to={`/informed-investor-rr/weekly-roundup`}
          onClick={() => onLinkClick()}
        >
          Weekly roundup
        </Link>
      ),
    },
  ]);

  const onRangeChange = (e: any) => {
    navigate({
      pathname: location.pathname,
      search: e
        ? `?start-date=${e[0].format('YYYY-MM-DD')}&end-date=${e[1].format(
            'YYYY-MM-DD'
          )}`
        : '',
    });
  };
  const onPriorityChange = (e: any) => {
    setPriority(e);
  };
  const onTabClick = (tabId: string) => {
    if (selectedTab !== 'video') {
      setPriority(undefined);
    }
    setSelectedTab(tabId);
  };
  const onLinkClick = () => {
    setPriority(undefined);
  };
  const onAllClick = () => {
    setPriority(undefined);
    navigate({
      pathname: location.pathname,
      search: ``,
    });
  };
  useEffect(() => {
    setIsLoading(true);
    const startDate = searchParams.get('start-date') || null || undefined;
    const endDate = searchParams.get('end-date') || null || undefined;
    const defaultDate =
      startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [];
    setDefaultDateRange(defaultDate);

    updateType &&
      getRecentUpdate(
        updateType,
        startDate,
        endDate,
        priority,
        current,
        pageSize
      )
        .then((response: any) => {
          setRecords(response.data);
          setTotal(response.pagination.total);
          setTabsWithCount([]);
          setSelectedMenu(`submenu-${updateType}`);
          setIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          setIsLoading(false);
        });
    console.log('priority', priority);
  }, [updateType, searchParams, current, priority]);

  const handlePageChange = (current: number) => {
    setCurrent(current);
  };
  const handleShowSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrent(current);
  };
  // const handlePriorityClick =(e: any) => {
  //     navigate({
  //         pathname: location.pathname,
  //         search: priority
  //           ?(`priority${priority}`)
  //           : "",
  //       });
  // };
  console.log('total', total);
  return (
    <>
      <ArticleLayout
        tabs={tabsWithCount}
        title={'Informed InvestoRR'}
        records={records}
        selectedMenu={selectedMenu}
        menuItems={menuItems}
        isLoading={isLoading}
        defaultDateRange={defaultDateRange}
        onRangeChange={onRangeChange}
        onPriorityChange={onPriorityChange}
        priority={priority}
        showPriorityFilter={true}
        onAllClick={onAllClick}
        // onPriorityClick={handlePriorityClick}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
        pagination={
          <Pagination
            onChange={handlePageChange}
            onShowSizeChange={handleShowSizeChange}
            showSizeChanger={true}
            current={current}
            total={total}
            pageSize={pageSize}
            className="fixed-pagination"
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        }
      />
    </>
  );
};

export default RecentUpdatesPage;
