import SenderAvatar from '../../components/Chat/SenderAvatar';
import Loader from '../../components/Layout/Loader';

const LoadingMessage: React.FC = () => {
  return (
    <>
      <div className={`chat-message message-bot`}>
        <SenderAvatar isBot={true} />
        <Loader showBox={false} style={{ justifyContent: 'left' }} />
      </div>
    </>
  );
};

export default LoadingMessage;
