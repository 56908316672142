import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge } from 'antd';
import ReactPlayer from 'react-player';
import ItemCard from './ListCard';
import VideoCard from './VideoCard';
import PriorityBadge from './PriorityBadge';
const { Title, Text, Paragraph } = Typography;

interface DateTitleProps {
  date: string;
}

const DateTitle: React.FC<DateTitleProps> = ({ date }) => {
  return (
    <Title
      level={5}
      style={{
        margin: '0',
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: 600,
      }}
    >
      {new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
      })}
    </Title>
  );
};

export default DateTitle;
