import React from 'react';
import { Button, Checkbox, Form, Input, Card, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form';
import { login } from '../services/ApiActions/authAct';
import Toast from '../services/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { forgetPassword } from '../services/ApiActions/authAct';
import Logo from '../components/Header/Logo';
const { Title } = Typography;

type FieldType = {
  email: string;
};

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const handleOnForgetClick = async () => {
    try {
      const resp = await forgetPassword({ email: 'anurodh.sahu@gmail.com' });
      console.log('mail-resp', resp);
    } catch (error) {
      console.log(error);
    } finally {
      console.log('finally');
    }
  };
  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    const { email } = values;

    console.log('Success:', values);
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo
  ) => {
    console.log('Failed:', errorInfo);
  };
  //remember: true
  return (
    <div
      style={{
        width: '400px',
        marginTop: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        <Logo styles={{ marginLeft: 'auto', marginRight: 'auto' }} />
      </div>

      <Card>
        <Title level={3} style={{ marginTop: '0' }}>
          Forgot Password?
        </Title>
        <p>
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.
        </p>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPasswordPage;
