import React, { useEffect, useState } from 'react';
import {
  Layout,
  Menu,
  Progress,
  Breadcrumb as AntBreadcrumb,
  Modal,
  Button,
  Typography,
} from 'antd';
import {
  CheckCircleFilled,
  LockFilled,
  UnlockFilled,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../components/Layout/Loader';
import ErrorCard from '../components/Error/ErrorCard';
import PageHeading from '../components/Layout/PageHeading';
import ErrorMessageCard from '../components/Error/ErrorMessageCard';

const { Sider } = Layout;

interface LMSLayoutProps {
  children: React.ReactNode;
  videoId?: string;
  course?: any;
  chapters?: any;
  onChapterClick: (videoId: string) => any;
  toggleModal: (isOpen: boolean) => any;
  isModalOpen?: boolean;
  elapsedTime?: any;
  error?: string;
}

const LMSLayout: React.FC<LMSLayoutProps> = ({
  children,
  videoId,
  course,
  chapters,
  onChapterClick,
  isModalOpen,
  toggleModal,
  error,
}) => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  if (error) {
    return (
      <ErrorMessageCard
        message="To access the videos of this module, please finish watching the
          previous and complete the accompanying MCQ."
      />
    );
  }
  if (!course) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }

  const watchedVideos =
    chapters.filter((video: any) => video.is_completed) || [];
  const progressPercentage =
    chapters.length > 0 ? (watchedVideos.length / chapters.length) * 100 : 0;
  console.log('course---?', course);
  return (
    <Layout>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PageHeading
          heading={course.moduleName}
          onBackClick={() => navigate(`/lms/course/${courseId}`)}
        />
      </div>
      <AntBreadcrumb style={{ margin: '0 0 16px' }}>
        <AntBreadcrumb.Item>
          <a href="/lms">LMS</a>
        </AntBreadcrumb.Item>
        <AntBreadcrumb.Item>
          <a href={`/lms/course/${courseId}`}>{course.courseName}</a>
        </AntBreadcrumb.Item>
        <AntBreadcrumb.Item>{course.moduleName}</AntBreadcrumb.Item>
      </AntBreadcrumb>
      <div>
        Your progress:{' '}
        {`${watchedVideos.length}/${chapters.length} videos watched`}
      </div>
      <Progress percent={Math.round(progressPercentage)} status="active" />

      <Layout style={{ paddingTop: '24px' }}>
        <Sider
          width={220}
          className="site-layout-background"
          style={{
            backgroundColor: 'transparent',
            maxHeight: 'calc(100vh - 100px)',
            overflow: 'auto',
          }}
        >
          <Menu
            key={videoId}
            mode="vertical"
            className="secondary-menu"
            defaultSelectedKeys={[videoId || 'assessment']}
            style={{
              height: '100%',
              borderRight: 0,
              backgroundColor: 'transparent',
            }}
            theme="light"
          >
            {chapters.map((chapter: any) => (
              <Menu.Item
                key={chapter.id}
                onClick={() => onChapterClick(chapter.id)}
              >
                <span
                  style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                >
                  {chapter.is_lock ? (
                    <LockFilled style={{ color: '#ccc' }} />
                  ) : chapter.is_completed ? (
                    <CheckCircleFilled style={{ color: '#52C41A' }} />
                  ) : (
                    <UnlockFilled style={{ color: '#52C41A' }} />
                  )}
                  <div className="video-title" style={{ flexGrow: 1 }}>
                    {chapter.name}
                  </div>
                </span>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <div
          style={{
            padding: '0 24px 24px',
            maxHeight: 'calc(100vh - 150px)',
            width: '100%',
            overflow: 'auto',
          }}
        >
          {children}
        </div>
      </Layout>
      <Modal
        title="Assessments Unlocked"
        centered
        open={isModalOpen}
        onCancel={() => toggleModal(false)}
        width={400}
        footer={[
          <Button key="back" onClick={() => toggleModal(false)}>
            Close
          </Button>,
        ]}
      >
        <p>Congratulations! You have unlocked the assessments.</p>
      </Modal>
    </Layout>
  );
};

export default LMSLayout;
