import api from "../api";
import { authApi } from "../apiVariables";

const handleApiCall = (apiConfig: any) => {
    return new Promise((resolve, reject) => {
        api(apiConfig)
            .then(resolve)
            .catch(reject);
    });
};


export const login = async (body: any) => {
    const { login } = authApi;
    return await handleApiCall({ ...login, body });
};
export const logout = async () => {
    //localStorage.removeItem('token');
    //localStorage.removeItem('username');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('chatMessages');
    return true;
};
export const  isLoggedIn = () => {
    // Check if authentication token exists
    // || localStorage.getItem('token')
    const authToken = sessionStorage.getItem('token');
    if (!authToken) {
        return false; // Token doesn't exist, user is not logged in
    }

    return true; // Token is present, valid, and not expired, user is logged in
}
export const forgetPassword = (body: any) => {
    const { forgetPassword: forgetPasswordConfig } = authApi;
 
    const apiConfig = { ...forgetPasswordConfig, body };
    return handleApiCall(apiConfig);
};


export const resetPassword = (body: any) => {
    console.log("body",body)
    const { resetPassword } = authApi;
    return handleApiCall({ ...resetPassword, body });
   
};


export const getUserRole = () => {
    const { userRole: userRoleConfig } = authApi;
    return handleApiCall({ ...userRoleConfig });
};
