import React, { useEffect, useState, useRef } from 'react';
import { Layout, Modal, Button, Typography } from 'antd';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';
import { isLastModule, isLastChapter } from '../helper/UtilityMethods';
import Quiz from '../components/Quiz/Quiz';
import LMSLayout from './LMSLayout';
import {
  submitQuizAnswers,
  getCourseById,
  updateElapsedTime,
  getQuestionByVideoId,
} from '../services/ApiActions/lmsAct';
import {
  CheckCircleOutlined,
  LockOutlined,
  LockFilled,
} from '@ant-design/icons';
import ErrorMessageCard from '../components/Error/ErrorMessageCard';

interface QuizQuestionsType {
  quiz_questions: { id: string; question_bank: any }[];
  is_attempted: boolean;
}
interface LMSVideoPageProps {
  onChapterClick: (videoId: string) => any;
  onAssesmentClick: () => any;
  onSetCourse?: (course: any) => any;
  course: any;
}
interface VideoProgressParams {
  video_time_stamp: number;
  videoId: string;
  percentage: number;
  courseId: string;
  isComplete?: boolean;
}
const LMSVideoPage = () => {
  const [isQuizLoading, setIsQuizLoading] = useState(true);
  const [processingForm, setProcessingForm] = useState(false);
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState('');
  //const [elapsedTime, setElapsedTime] = useState(0);
  //const [playedDuration, setPlayedDuration] = useState(0);
  const playedDuration = useRef(0);
  const [duration, setDuration] = useState(0);
  const [isAllCorrect, setIsAllCorrect] = useState(false);
  const [isLastModuleChapter, setIsLastModuleChapter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [quizQuestions, setQuizQuestions] = useState<QuizQuestionsType>({
    quiz_questions: [],
    is_attempted: false,
  });
  const player = useRef<ReactPlayer>(null);
  const {
    courseId,
    moduleId,
    chapterId: videoId,
  } = useParams<{ courseId: string; chapterId: string; moduleId: string }>();
  const userString = sessionStorage.getItem('user');
  const sessionCourses = sessionStorage.getItem('courses');
  const courseList = sessionCourses && JSON.parse(sessionCourses);
  const user = userString ? JSON.parse(userString) : undefined;
  const navigate = useNavigate();
  const [course, setCourse] = useState<any>(null);
  const [selectedVideo, setSelectedVideo] = useState<{
    id: string;
    name: string;
    video_link: string;
    progressPercentage: number;
    is_lock: boolean;
    video_time_stamp: number;
    percentage: number;
    is_completed: boolean;
    preventRefresh: boolean;
  } | null>(null);
  useEffect(() => {
    courseId &&
      moduleId &&
      getCourseById(courseId, moduleId)
        .then((response: any) => {
          setCourse(response.data);
          if (!videoId) {
            navigate(
              `/lms/course/${courseId}/${moduleId}/chapter/${response.data.chapters[0].id}`
            ); //fix me get next chapter
            return;
          }
        })
        .catch((error) => {
          error && setError(error.message);
        })
        .finally(() => {});
  }, [moduleId, videoId]);
  useEffect(() => {
    const currentVideo = course?.chapters.find((v: any) => v.id == videoId);
    setSelectedVideo(currentVideo);
  }, [course, videoId]);

  const handleonChapterClick = (chapterId: any) => {
    navigate(`/lms/course/${courseId}/${moduleId}/chapter/${chapterId}`);
  };
  //
  const handleOnChange = (formItems: any[]) => {
    const disableButton = formItems.find((formItem) => !formItem.touched)
      ? true
      : false;
    setIsSubmitDisabled(disableButton);
  };
  const fetchQuizQuestions = async () => {
    try {
      setIsQuizLoading(true);
      if (videoId) {
        const response: any = await getQuestionByVideoId(videoId);
        setIsAllCorrect(false);
        setIsQuizLoading(false);
        setQuizQuestions(response.data);
        setIsQuizSubmitted(false);
      }
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
    }
  };
  useEffect(() => {
    if (!selectedVideo) {
      return;
    }
    if (!selectedVideo?.preventRefresh) {
      const timer = setTimeout(() => {
        if (player.current) {
          if (selectedVideo.video_time_stamp > 0) {
            const timeStamp = Number(selectedVideo.video_time_stamp == duration)
              ? 0
              : selectedVideo.video_time_stamp;
            player.current.seekTo(timeStamp, 'seconds');
            playedDuration.current = timeStamp;
            clearTimeout(timer);
          }
        } else {
          //setPlaying(true)
        }
      }, 1000);
    }

    //call question api

    //selectedVideo?.is_completed && fetchQuizQuestions();
  }, [selectedVideo]);

  const handleOnProgress = (progressDuration: number) => {
    const absDiff = Math.abs(progressDuration - playedDuration.current);
    if (!selectedVideo || absDiff < 30) return;
    const { id: videoId } = selectedVideo;
    const percentage = Math.trunc((progressDuration / duration) * 100);
    // Update the video progress
    courseId &&
      updateVideoProgress({
        courseId,
        videoId,
        video_time_stamp: progressDuration,
        percentage,
      });

    // Update playedDuration with the current progressDuration
    playedDuration.current = progressDuration;
  };
  const updateVideoProgress = async ({
    video_time_stamp,
    videoId,
    percentage,
    courseId,
    isComplete = false,
  }: VideoProgressParams): Promise<void> => {
    try {
      await updateElapsedTime({
        video_time_stamp: Number(video_time_stamp.toFixed(2)),
        chapter_id: videoId,
        video_percentage: Number(percentage.toFixed(2)),
        module_id: Number(moduleId), // Assuming `moduleId` is globally accessible or passed
        course_id: Number(courseId),
        ...(isComplete && { is_completed: true }), // Conditionally add `is_completed`
      });
      // Handle the response if necessary
    } catch (error) {
      console.error('Error updating elapsed time:', error);
    }
  };
  const handleOnSubmitQuiz = async (
    data: any,
    videoId: string,
    courseId: string,
    moduleId: string
  ) => {
    try {
      setProcessingForm(true);
      const newCourse = { ...course }; // Use spread operator for shallow copy
      const currentIndex = newCourse.chapters.findIndex(
        (v: any) => v.id === Number(videoId)
      );
      const currentModuleIndex = courseList.modules.findIndex(
        (module: any) => module.id == moduleId
      );
      const isLastChap: boolean = isLastChapter(course.chapters, currentIndex);
      const isLastMod: boolean = isLastModule(
        courseList.modules,
        currentModuleIndex
      );
      const response: any = await submitQuizAnswers({
        ...data,
        ...(isLastChap && { is_last: true }),
        ...(isLastMod && { is_last_module: true }),
      });
      setQuizQuestions({
        quiz_questions: response.data.quiz_response,
        is_attempted: false,
      } as any);
      setIsQuizSubmitted(true);
      setProcessingForm(false);

      if (!response.data.quiz_response.find((r: any) => !r.is_correct)) {
        //check all correct
        newCourse.chapters[currentIndex].is_completed = true;
        //console.log('last--', isLastChap, isLastMod);
        if (!isLastChap) {
          newCourse.chapters[currentIndex + 1].is_lock = false;
          newCourse.chapters[currentIndex + 1].video_link =
            response.data.next_chapter_url;
        } else if (isLastChap && isLastMod) {
          !response.data.assessment_lock_status && setIsOpen(true);
          setIsLastModuleChapter(true);
        }
        setCourse(newCourse);
        setIsAllCorrect(true);
      }
    } catch (error) {
      console.error('Error submitting quiz:', error);
      setProcessingForm(false);
    }
  };

  const handleOnNextClick = (videoId: string) => {
    const currentIndex = course.chapters.findIndex(
      (vid: any) => vid.id == videoId
    );
    if (!isLastChapter(course.chapters, currentIndex)) {
      handleonChapterClick(course.chapters[currentIndex + 1].id);
    } else {
      //next module
      const currentModuleIndex = courseList.modules.findIndex(
        (m: any) => m.id == moduleId
      );
      if (isLastModule(courseList.modules, currentModuleIndex)) {
        navigate(`/lms/course/${courseId}`); //will call the first chapter of the module
      } else {
        const nextModule = courseList.modules[currentModuleIndex + 1];
        navigate(`/lms/course/${courseId}/${nextModule.id}/chapter`); //will call the first chapter of the module
      }
    }
  };
  return (
    <LMSLayout
      videoId={videoId}
      course={course}
      chapters={course?.chapters}
      onChapterClick={(videoId) => handleonChapterClick(videoId)}
      //elapsedTime={elapsedTime}
      isModalOpen={isOpen}
      toggleModal={(isOpen) => setIsOpen(isOpen)}
      error={error}
    >
      <Typography.Title
        className="page-sub-heading"
        level={4}
        style={{ margin: '0 0 10px 0', fontWeight: 600 }}
      >
        {selectedVideo?.name}
      </Typography.Title>
      {!selectedVideo?.is_lock ? (
        <>
          {selectedVideo && (
            <ReactPlayer
              key={selectedVideo.id}
              width="100%"
              ref={player}
              controls
              playing={true}
              url={selectedVideo?.video_link}
              style={{ maxWidth: '900px', height: 'auto' }}
              onDuration={(sec) => {
                setDuration(sec);
              }}
              onPause={() => setPlaying(false)}
              onEnded={() => {
                setPlaying(false);
                // const newCourse = JSON.parse(JSON.stringify(course))
                // const chapterIndex = newCourse.chapters.findIndex((chapter: any) => chapter.id == selectedVideo.id)
                // newCourse.chapters[chapterIndex].is_completed = true;
                // newCourse.chapters[chapterIndex].video_time_stamp = duration;
                // setCourse(newCourse)
                setSelectedVideo({
                  ...selectedVideo,
                  is_completed: true,
                  video_time_stamp: duration,
                  preventRefresh: true,
                });
                courseId &&
                  updateVideoProgress({
                    videoId: selectedVideo.id,
                    courseId,
                    video_time_stamp: Number(duration.toFixed(2)),
                    percentage: 100,
                    isComplete: true,
                  });
                fetchQuizQuestions();
              }}
              onPlay={() => setPlaying(true)}
              onProgress={(e) => {
                //setPlayedDuration(e.playedSeconds);

                //setElapsedTime((curr) => curr + 1000);
                handleOnProgress(e.playedSeconds);
              }}
              onReady={() => setPlaying(true)}
            />
          )}
          {selectedVideo?.is_completed ? (
            !isQuizLoading &&
            quizQuestions &&
            videoId &&
            moduleId &&
            courseId && (
              <Quiz
                videoId={videoId}
                moduleId={moduleId}
                courseId={courseId}
                processingForm={processingForm}
                onSubmitQuiz={handleOnSubmitQuiz}
                onNextClick={handleOnNextClick}
                quizQuestion={quizQuestions.quiz_questions}
                isModalLoading
                isAttempted={quizQuestions.is_attempted}
                isAllCorrect={isAllCorrect}
                isQuizSubmitted={isQuizSubmitted}
                isLastModuleChapter={isLastModuleChapter}
                isSubmitDisabled={isSubmitDisabled}
                onValueChange={(formItems: any) => handleOnChange(formItems)}
              />
            )
          ) : (
            <ErrorMessageCard message="Watch the full video to unlock the questions." />
          )}
        </>
      ) : (
        <ErrorMessageCard
          message="To access this video, please finish watching the previous and
            complete the accompanying MCQ."
        />
      )}
    </LMSLayout>
  );
};

export default LMSVideoPage;
