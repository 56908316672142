export const getUserInitialLetter = () => {
  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;
  let initialLetter = '';
  if (user && user.username) {
    initialLetter = user.username.trim()[0].toUpperCase();
  }
  return initialLetter;
};
export const getUserName = () => {
  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;
  let name = '';
  if (user && user.username) {
    name = capitalizeName(user.username);
  }
  return name;
};
export const capitalizeName = (username: string) => {
  return username
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export const isLastChapter = (
  chapters: any[],
  chapterIndex: number
): boolean => {
  return chapterIndex >= chapters.length - 1;
};

export const isLastModule = (modules: any[], moduleIndex: number): boolean => {
  return moduleIndex >= modules.length - 1;
};
export const formatDate = (dateString: any) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const options: any = { hour: 'numeric', minute: 'numeric', hour12: true };
  const time = new Intl.DateTimeFormat('en-US', options)
    .format(date)
    .toLowerCase();

  return `${day} ${month} ${year}, ${time}`;
};
export const filterVideoData = (records: any, tabId: string) => {
  let recordList: any;
  if (tabId === 'video') {
    const fRecords: any = [];
    records = Array.isArray(records) ? records : [records];
    recordList = records.map((record: any) => {
      const filteredRecords = record.records
        .filter((rec: any) => rec.video_link)
        .map((r: any) => {
          return {
            id: `video-${record.id}-${r.id}`,
            title: r.title,
            description: r.description,
            video_link: r.video_link,
            date: r.date,
          };
        });
      if (filteredRecords.length > 0) {
        fRecords.push({
          date: record.date,
          records: filteredRecords,
        });
      }
    });
    return fRecords;
  } else {
    recordList = records;
  }
  console.log('recordList - ', recordList);
  return recordList;
};

export const filterWhatsTrendingData = (response: any, tabId: string) => {
  let recordList: any;
  const data = response?.data?.data || [];

  if (tabId === 'video') {
    const fRecords: any = [];

    // Ensure `data` is an array
    const recordsArray = Array.isArray(data) ? data : [data];

    recordList = recordsArray.map((record: any) => {
      // Ensure `record.records` exists and is an array
      if (record?.records && Array.isArray(record.records)) {
        const filteredRecords = record.records
          .filter((rec: any) => rec?.video_link) // Safely filter records with video links
          .map((r: any) => {
            return {
              id: `video-${r.id}`, // Unique ID for each video
              title: r.title,
              description: r.description,
              video_link: r.video_link,
              date: r.date,
            };
          });

        if (filteredRecords.length > 0) {
          fRecords.push({
            date: record.date,
            records: filteredRecords,
          });
        }
      } else {
        console.warn("No 'records' found or not an array for record:", record);
      }
    });

    return fRecords;
  } else {
    recordList = Array.isArray(data) ? data : [data];
  }

  console.log('recordList - ', recordList);
  return recordList;
};

export const formatData = (records: any, tabId: string) => {
  const recordList: any = [];
  if (tabId === 'news_letter_summary') {
    records.map((record: any) => {
      record.records.map((r: any) => {
        r.news_letter_summary &&
          recordList.push({
            id: `summary-${r.id}-${r.news_letter_summary.id}`,
            title: r.news_letter_summary.title,
            description: r.news_letter_summary.description,
            pdfURL: r.news_letter_summary.pdf?.url,
            date: r.date,
          });
        return;
      });
    });
  } else if (tabId === 'news_letter') {
    records.map((record: any) => {
      record.records.map((r: any) => {
        recordList.push({
          id: `newsletter-${r.id}`,
          title: r.title,
          description: r.description,
          pdfURL: r.pdf?.url,
          date: r.date,
        });
      });
    });
  } else if (tabId === 'pdf') {
    records.map((record: any) => {
      record.records.map((r: any) => {
        recordList.push({
          id: `pdf-${r.id}`,
          title: r.title,
          description: r.description,
          pdfURL: r.pdf?.url,
          date: r.date,
        });
      });
    });
  } else if (tabId === 'video') {
    records.map((record: any) => {
      record.records.map((r: any) => {
        r.video_link &&
          recordList.push({
            id: `newsletter-${r.id}`,
            title: r.title,
            description: r.description,
            videoURL: r.video_link,
            date: r.date,
          });
      });
    });
  }
  return recordList;
};
export const defaultPageSize = 50;
