import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseCard from '../components/LMSComponent/CourseCard';
import { getAllCourses } from '../services/ApiActions/lmsAct';
import { Typography, Empty } from 'antd';
import BreadcrumbContext from '../components/Context';
import Loader from '../components/Layout/Loader';
import PageHeading from '../components/Layout/PageHeading';

const { Text, Title } = Typography;

const LMSPage = () => {
  const navigate = useNavigate();
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    getAllCourses()
      .then((response: any) => {
        if (response.status === 'success') {
          console.log('courses', response.data.course);
          setCourses(response.data.course);
        }
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleOnCourseClick = (courseId: string) => {
    navigate(`/lms/course/${courseId}`);
  };
  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <div>
      <PageHeading heading="Learning Management System" />
      {courses.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          {courses.map((course: any) => (
            <CourseCard
              key={course.id}
              title={course.name}
              imageUrl={course.thumbnail.url || ''}
              completionPercentage={course.percentage.toFixed(0)}
              count={course.moduleCount}
              cardType={'module'}
              onPlayButtonClick={() => handleOnCourseClick(course.id)}
            />
          ))}
        </div>
      ) : (
        <Empty
          style={{ marginTop: '5em' }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<Typography.Text>No courses available.</Typography.Text>}
        />
      )}
    </div>
  );
};

export default LMSPage;
