import api from "../api";
import { whatsTrendingApi } from "../apiVariables";

const handleApiCall = (apiConfig: any) => {
    return new Promise((resolve, reject) => {
        api(apiConfig)
            .then(resolve)
            .catch(reject);
    });
};

export const getWhatsTrending = (reportName: string, startDate?: string, endDate?: string, current?: number, pageSize?: number) => {
   
    let api = ''
    if (reportName === 'founders-desk') {
        api = `/api/trending/founders-desk`;
    } else if (reportName === 'must-watch') {
        api = `/api/trending/must-watch`;
    } else if (reportName === 'corporate-video') {
        api = `/api/trending/corporate-video`;
    } else if (reportName === 'brand-video') {
        api = `/api/trending/brand-video`;
    } else if (reportName === 'product-video') {
        api = `/api/trending/product-video`;
    }

    if (startDate || endDate || current || pageSize) {
        let filters = '?';
        if (startDate) {
            filters += `&start_date=${startDate}`
        }
        if (endDate) {
            filters += `&end_date=${endDate}`
        }
        if (current) {
            filters += `&page=${current}`
        }
        if (pageSize) {
            filters += `&pageSize=${pageSize}`
        }
        api += filters;
    }
    const { getTrendingParameters: whatsTrendingConfig } = whatsTrendingApi;
    const apiConfig = { ...whatsTrendingConfig, api: api };
    return handleApiCall(apiConfig);
};