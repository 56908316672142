import api from '../api';
import { adminPanelApi, reviewApi, peopleApi, authApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};

export const getCourseCompletion = () => {
  const { courseCompletionRate: courseCompletionConfig } = adminPanelApi;
  const apiConfig = {
    ...courseCompletionConfig,
    api: `/api/spoonfeed/lms/course-completion-rate`,
  };
  return handleApiCall(apiConfig);
};

export const getAssessmentReview = () => {
  const { assessmentReview: assessmentReviewConfig } = adminPanelApi;
  const apiConfig = {
    ...assessmentReviewConfig,
    api: `/api/spoonfeed/lms/assessment-review`,
  };
  return handleApiCall(apiConfig);
};

export const getCourseCompletionList = () => {
  const { courseCompletionList: courseCompletionListConfig } = adminPanelApi;
  const apiConfig = {
    ...courseCompletionListConfig,
    api: `/api/spoonfeed/lms/course-completion-list`,
  };
  return handleApiCall(apiConfig);
};

export const quizByChapterId = (chapter_id: string, user_Id: string) => {
  const api = `/api/spoonfeed/admin/quiz/get-quiz-response?chapter_id=${chapter_id}&user_id=${user_Id}`;
  const { getQuizQuestions: quizQuestionsConfig } = reviewApi;
  const apiConfig = { ...quizQuestionsConfig, api: api };
  return handleApiCall(apiConfig);
};
export const getAssessmentByCourseId = (
  assessment_id: string,
  course_id: string,
  user_id: string
) => {
  const api = `/api/spoonfeed/admin/assessment/get-assessment-response?assessment_id=${assessment_id}&course_id=${course_id}&user_id=${user_id}`;
  const { getAssesmentQuestions: assessmentQuestionsConfig } = reviewApi;
  const apiConfig = { ...assessmentQuestionsConfig, api: api };
  return handleApiCall(apiConfig);
};

export const getAssessmentResultByAdmin = (body: any) => {
  const { assessmentResultByAdmin: assessmentResultByAdminConfig } = reviewApi;
  const apiConfig = { ...assessmentResultByAdminConfig, body };
  return handleApiCall(apiConfig);
};

// api for People Page
export const peopleAssessmentStatus = (
  user_id: string,
  course_id: string | null
) => {
  // let api;
  // if (!course_id) {
  //     // if course_id is null then pass only user_id
  //     api = `/api/spoonfeed/lms/assessment-status/user/${user_id}`
  // }
  // else {
  //     //   if course_id and user_id is  not null then pass both
  //     api = `/api/spoonfeed/lms/assessment-status/user/${user_id}?course_id=${course_id}`
  // }
  const api = `/api/spoonfeed/lms/assessment-status/user/${user_id}${
    course_id ? `?course_id=${course_id}` : ''
  }`;
  const { getAssessmentStatus: assessmentStatusConfig } = peopleApi;
  const apiConfig = { ...assessmentStatusConfig, api: api };
  return handleApiCall(apiConfig);
};
export const courseAssessmentStatus = (user_id: string, course_id: string) => {
  // console.log("course_id", course_id)
  // let api;
  // if (!course_id) {
  //     // if course_id is null then pass only user_id
  //     api = `/api/spoonfeed/lms/assessment-status/user/${user_id}`
  // }
  // else {
  //     //   if course_id and user_id is  not null then pass both
  //     api = `/api/spoonfeed/lms/assessment-status/user/${user_id}?course_id=${course_id}`
  // }
  const api = `/api/spoonfeed/lms/assessment-result/module-percentage/user/${user_id}${
    course_id ? `?course_id=${course_id}` : ''
  }`;
  const { getCourseStatus: coursetStatusConfig } = peopleApi;
  const apiConfig = { ...coursetStatusConfig, api: api };
  return handleApiCall(apiConfig);
};

// Api to get User Details
export const userDetails = (userId: string) => {
  const api = `/api/users/${userId}`;
  const { getUsers: usersListConfig } = authApi;
  const apiConfig = { ...usersListConfig, api: api };
  return handleApiCall(apiConfig);
};

export const getAssessmentAttempted = (userId: string) => {
  const api = `/api/spoonfeed/lms/attempted-assessment/user/${userId}`;
  const { assessmentAttempted: assessmentAttemptedConfig } = peopleApi;
  const apiConfig = { ...assessmentAttemptedConfig, api: api };
  return handleApiCall(apiConfig);
};

export const getUsers = (departmentName?: string) => {
  let api = `/api/spoonfeed/lms/course-completion-list`;
  if (departmentName) {
    api += `?department=${departmentName}`;
  }
  const { courseCompletionList: courseCompletionConfig } = adminPanelApi;
  const apiConfig = { ...courseCompletionConfig, api: api };
  return handleApiCall(apiConfig);
};
export const getDepartments = () => {
  const { userDepartment: userDepartmentConfig } = adminPanelApi;
  const apiConfig = { ...userDepartmentConfig };
  return handleApiCall(apiConfig);
};
export const getCourseStatics = () => {
  const { courseCompletionRate: courseCompletionRateConfig } = adminPanelApi;
  const apiConfig = { ...courseCompletionRateConfig };
  return handleApiCall(apiConfig);
};
export const getAssessmentStatics = () => {
  const { assessmentReview: assessmentReviewConfig } = adminPanelApi;
  const apiConfig = { ...assessmentReviewConfig };
  return handleApiCall(apiConfig);
};
export const getCourses = () => {
  const { courses: coursesConfig } = adminPanelApi;
  const apiConfig = { ...coursesConfig };
  return handleApiCall(apiConfig);
};
export const getAllModulesByCourseId = (courseId: string, userId: string) => {
  const { modules: courseByIdConfig } = adminPanelApi;
  const apiConfig = {
    ...courseByIdConfig,
    api: `/api/spoonfeed/lms/modules-for-admin/${courseId}/${userId}`,
  };
  return handleApiCall(apiConfig);
};
export const getChaptersByModuleId = (courseId: string, moduleId: string) => {
  const { chapters: courseByIdConfig } = adminPanelApi;
  const apiConfig = {
    ...courseByIdConfig,
    api: `/api/spoonfeed/lms/chapters-for-admin/${courseId}/${moduleId}`,
  };
  return handleApiCall(apiConfig);
};
export const createInitialProgress = () => {
  const { createInitialProgress: createInitialProgressConfig } = adminPanelApi;
  const apiConfig = { ...createInitialProgressConfig };
  return handleApiCall(apiConfig);
};
