import dayjs, { Dayjs } from 'dayjs';
import api from '../api';
import { notificationApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};

export const adminNotification = (
  updateType?: string,
  id?: number,
  read?: boolean,
  assessment_result?: string,
  createdAt?: string,
  updatedAt?: string,
  publishedAt?: string,
  assessment_id?: string
) => {
  let api = `/api/spoonfeed/lms/admin-notification`;
  const { adminNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  return handleApiCall(apiConfig);
};
export const readSingleAdminNotification = (id: number) => {
  const api = `/api/spoonfeed/lms/admin-notification-read?id=${id}`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  console.log(apiConfig);
  return handleApiCall(apiConfig);
};
export const readAllAdminNotification = () => {
  const api = `/api/spoonfeed/lms/admin-all-notification-read`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  console.log(apiConfig);
  return handleApiCall(apiConfig);
};
export const getAllAdminNotificationFilter = (read: boolean) => {
  const api = `/api/spoonfeed/lms/admin-notification?read=${read}`;
  const { userNotification: notificationConfig } = notificationApi;
  const apiConfig = { ...notificationConfig, api: api };
  console.log(apiConfig);
  return handleApiCall(apiConfig);
};
export const getAdminNotifications = (
  read?: boolean,
  current?: number,
  pageSize?: number
) => {
  let queryParams: string[] = [];

  // Add `read` parameter if defined
  if (read !== undefined) {
    queryParams.push(`read=${read}`);
  }

  // Add `page` parameter if provided
  if (current) {
    queryParams.push(`page=${current}`);
  }

  // Add `pageSize` parameter if provided
  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  // Join the query parameters to form the full query string
  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

  // Construct the full API URL
  const api = `/api/spoonfeed/lms/admin-notification${queryString}`;

  // Get the API config for admin notifications
  const { adminNotification: adminNotificationConfig } = notificationApi;
  const apiConfig = { ...adminNotificationConfig, api };

  // Call the API
  return handleApiCall(apiConfig);
};
