import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/ApiActions/authAct';
import Toast from '../../services/ToastMessage';
import PageHeading from '../Layout/PageHeading';
const { Title } = Typography;
type Props = {};
interface catchError {
  message: 'string';
}
// const bcrypt = require("bcrypt");
// const saltRounds = 10;
const ResetPassword = (props: Props) => {
  const navigator = useNavigate();
  const [isProcesssing, setIsProcesssing] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('user') || '{}')
  );

  const handleResetPassword = async ({
    oldPassword,
    newPassword,
    confirmPassword,
  }: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setIsProcesssing(true);
    try {
      const response: any = await resetPassword({
        currentPassword: oldPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword,
      });
      if (response) {
        //const { jwt } = response;
        //sessionStorage.setItem("token", jwt);
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('course_name');
        sessionStorage.removeItem('courses');
        navigator('/password-change-success');
      } else {
        message.error('Password reset failed. Please try again.');
      }
    } catch (e) {
      let errorMessage = 'Something went wrong';
      if (e?.error?.message) {
        errorMessage = e.error.message;
      }

      Toast({
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setIsProcesssing(false);
    }
  };

  return (
    <div>
      <PageHeading heading="Reset Password" />
      <Card
        style={{
          width: 448,
          marginTop: 100,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Title
          level={4}
          className="heading"
          style={{ marginTop: 0, fontSize: 24, fontWeight: 600 }}
        >
          Reset Your Password
        </Title>
        <Form
          layout="vertical"
          onFinish={handleResetPassword}
          style={{ marginTop: '20px', fontSize: 24, fontWeight: 600 }}
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              { required: true, message: 'Please input your old password!' },
            ]}
            style={{ marginBottom: '45px' }}
          >
            <Input placeholder="Enter Your Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: 'Please input your new password!' },
            ]}
          >
            <Input placeholder="Enter a Strong Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords do not match!')
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Confirm your New Password" />
          </Form.Item>
          <div>
            <Button
              loading={isProcesssing}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Continue
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
