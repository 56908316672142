import React from 'react';
import { Typography, Badge, Row, Col, Tag } from 'antd';
const { Title } = Typography;
type AudioPlayerProps = {
  summaries: string[];
};
function AudioSummary({ summaries }: AudioPlayerProps) {
  if (summaries.length <= 0) {
    return <></>;
  }
  return (
    <div
      className="audio-summary-card"
      style={{ width: '350px', background: 'none' }}
    >
      <Title
        className="keynote-heading page-heading"
        level={5}
        style={{ margin: '0 0 3px 0' }}
      >
        Keynote
      </Title>
      <Row gutter={[10, 5]}>
        {summaries.map((summary) => {
          return (
            <Tag
              bordered
              style={{
                background: 'none',
                borderColor: '#B4A499',
                fontSize: '12px',
                fontWeight: '400',
                color: '#1D322D',
              }}
            >
              {summary}
            </Tag>
          );
        })}
      </Row>
    </div>
  );
}
export default AudioSummary;
