import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { BreadcrumbProvider } from '../Context';
import { AdminNotificationProvider } from '../Context/AdminNotificationContext';
import AdminTopBar from './AdminTopBar';
const { Content, Sider } = Layout;
const AdminLayout = () => {
  return (
    <>
      <AdminNotificationProvider>
        <Layout>
          <Layout>
            <AdminTopBar />
            <Layout style={{ padding: '0 24px', flexDirection: 'row' }}>
              <Content
                style={{
                  padding: '10px 0 0',
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <BreadcrumbProvider>
                  <Outlet />
                </BreadcrumbProvider>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </AdminNotificationProvider>
    </>
  );
};
export default AdminLayout;
