import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge } from 'antd';
import ReactPlayer from 'react-player';
import ItemCard from './ListCard';
import VideoCard from './VideoCard';
import PriorityBadge from './PriorityBadge';
const { Title, Text, Paragraph } = Typography;

interface ArticleTitleProps {
  title: string;
  priority?: string;
  date?: string;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({
  priority,
  title,
  date,
}) => {
  return (
    <Flex align="center" style={{ marginBottom: '10px' }}>
      {priority && <PriorityBadge priority={priority} />}
      <Title
        level={4}
        className="heading artitle-heading ellipsis-text "
        style={{
          flexGrow: 1,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        {title}
      </Title>
      {date && (
        <div style={{ alignSelf: 'flex-end' }}>
          <Text
            type="secondary"
            style={{
              whiteSpace: 'nowrap',
              marginLeft: '10px',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {new Date(date).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Text>
        </div>
      )}
    </Flex>
  );
};

export default ArticleTitle;
