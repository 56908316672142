import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Card,
  Space,
  Flex,
  Typography,
  Button,
  Badge,
  message,
  Pagination,
} from 'antd';
import {
  adminNotification as fetchAllNotifications,
  getAllAdminNotificationFilter,
  readSingleAdminNotification,
  readAllAdminNotification,
  getAdminNotifications,
} from '../services/ApiActions/notificationAct';
import { defaultPageSize, formatDate } from '../helper/UtilityMethods';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AdminNotification as AdminNotificationContext } from '../components/Context/AdminNotificationContext';
import Loader from '../components/Layout/Loader';
const { Title, Text } = Typography;

const NotificationsPage = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const notificationStatus = searchParams.get('status');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);
  const [records, setRecords] = useState<any[] | undefined>();
  const [id, setId] = useState<number | undefined>(undefined);
  const [seen, setSeen] = useState<boolean>(false);
  const { updateType } = useParams<{ updateType?: string }>();
  const {
    unreadCount,
    setUnreadCount,
    markNotificationRead,
    recentNotifications,
  } = AdminNotificationContext();

  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  //const [universalUnread, setUniversalUnread] = useState(unreadCount);
  //const [showingUnread, setShowingUnread] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        !records && setIsLoading(true);
        // const status =
        //   notificationStatus && notificationStatus === 'unread'
        //     ? false
        //     : undefined;
        //setIsLoading(true);
        const status =
          notificationStatus && notificationStatus === 'unread'
            ? false
            : undefined;

        const response: any = await getAdminNotifications(
          status,
          currentPage,
          pageSize
        );
        //setRecentNotifications(response.data.results.slice(0, 5));
        setIsLoading(false);

        if (response.status === 'success') {
          setRecords(response.data.results);
          // setFilteredRecords(response.data.results);
          setFilteredRecords(response.data.results);
          setTotal(
            response.data.pagination?.total || response.data.results.length
          );
          //setUniversalUnread(unreadCount);
        } else {
          console.error(
            'Failed to fetch:',
            response.message || 'Unknown error'
          );
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchNotifications();
  }, [notificationStatus, notificationStatus, currentPage, pageSize]);
  //
  // useEffect(() => {
  //     if (id) {
  //         setId((id));
  //     }
  //     fetchData()
  //     setIsLoading(true);

  // }, [updateType, seen]);

  const fetchData = () => {
    fetchAllNotifications(updateType)
      .then((response: any) => {
        setRecords(response.data.results);
        // console.log("response", response.data)
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleShowSizeChange = (current: number, size: number) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const handleNotificationClick = async (notification: any) => {
    if (notification.read) {
      notification.submitted_user.id &&
        navigate(`/people/${notification.submitted_user.id}/assessment`);
      return;
    }
    try {
      const markRead: any = await readSingleAdminNotification(notification.id);

      if (markRead?.status === 'success') {
        const newFilteredRecords = [...filteredRecords];
        const updatedFilteredRecords = newFilteredRecords.map((record) => {
          if (record.id == notification.id) {
            record.read = true;
          }
          return record;
        });
        setFilteredRecords(updatedFilteredRecords);
        setUnreadCount(unreadCount > 0 ? unreadCount - 1 : 0);
        //setUniversalUnread(unreadCount > 0 ? unreadCount - 1 : 0);
        //console.log(updatedFilteredRecords);
        markNotificationRead(notification);
        if (notification.submitted_user.id) {
          navigate(`/people/${notification.submitted_user.id}/assessment`);
        }
      }
    } catch (error) {}
  };

  const handleArrow = () => {
    navigate('/admin');
  };
  //
  const readAllNotificationMark = async () => {
    if (unreadCount <= 0) {
      message.info(`No unread messages found`);
      return;
    }
    try {
      const readNotification: any = await readAllAdminNotification();
      if (readNotification?.status === 'success') {
        message.success(`Marked all notifications as 'read' successfully`);
        setUnreadCount(0);
        //setUniversalUnread(0);
        if (notificationStatus && notificationStatus === 'unread') {
          navigate('/admin-notification');
        } else {
          const newFilteredRecords = [...filteredRecords];
          const updatedFilteredRecords = newFilteredRecords.map((record) => {
            record.read = true;
            return record;
          });
          setFilteredRecords(updatedFilteredRecords);
          markNotificationRead();
        }
      } else {
        message.error(`Something went wrong`);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  const PageHeader: React.FC = () => (
    <div>
      <Space size="large" align="center" style={{ margin: '0 0 16px' }}>
        <div onClick={handleArrow} className="pointer">
          <ArrowLeftOutlined
            style={{ fontSize: '23px', marginRight: '10px' }}
            onClick={() => navigate(-1)}
          />
        </div>

        <Title
          level={4}
          style={{ margin: 0, fontSize: '32px', fontWeight: 500 }}
        >
          Notifications Center
        </Title>
      </Space>
    </div>
  );
  // const readAllNotification = () => {
  //   readAllAdminNotification();
  //   fetchData();
  // };
  const getAllNotificationFilter = async () => {
    try {
      let res: any = await getAllAdminNotificationFilter(false);
      setRecords(res.data);
    } catch (error) {
      console.log('Error', error);
    }
  };
  if (isLoading || !recentNotifications) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <>
      <div className="notifications-page">
        {contextHolder}
        <PageHeader />

        <div className="notification-spacing card-content-center">
          {recentNotifications?.length === 0 ? (
            <div className="notification-spacing">
              <div className="empty-notifications">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Flex justify="center" align="center" vertical={true}>
                    <img src="/images/icons/Vector.png" alt="Bell Icon" />
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      No Notifications Yet!
                    </div>
                    <div style={{ fontSize: '18px', fontWeight: 400 }}>
                      When you get notifications, they’ll show up here.
                    </div>
                  </Flex>
                </div>
              </div>
            </div>
          ) : (
            <div className="notification-ui">
              <Flex className="notifications-header" gap={16}>
                <Flex gap={16}>
                  <Button
                    className="tab-button-noti"
                    type={
                      !(notificationStatus && notificationStatus === 'unread')
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      //setShowingUnread(false);
                      navigate('/admin-notification');
                    }}
                  >
                    All
                  </Button>
                  <Button
                    className="tab-button-noti"
                    type={
                      notificationStatus && notificationStatus === 'unread'
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      //setShowingUnread(true);
                      navigate('/admin-notification?status=unread');
                    }}
                  >
                    Unread{' '}
                    <Badge
                      style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      count={unreadCount}
                    />
                  </Button>
                </Flex>
                <Button
                  type="link"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => readAllNotificationMark()}
                >
                  Mark all read
                </Button>
              </Flex>
              <div
                style={{
                  height: 'calc(100vh - 242px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  padding: '10px',
                  marginBottom: '10px',
                }}
              >
                {
                  //<Badge style={{paddingLeft: '10px', paddingRight: '10px'}} count={1} />
                  filteredRecords.length > 0 ? (
                    <div className="notification-card">
                      {filteredRecords.map((notification) => (
                        <Card
                          key={notification.id}
                          style={{
                            backgroundColor: !notification.read
                              ? '#F0E2D5'
                              : 'white',
                          }}
                          onClick={() => handleNotificationClick(notification)}
                          className={`notification-item ${
                            !notification.read ? 'unread' : ''
                          }pointer`}
                        >
                          <div style={{ marginBottom: '5px' }}>
                            <Text>{notification.message}</Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: '12px' }} type="secondary">
                              {formatDate(notification.createdAt)}
                            </Text>{' '}
                            {!notification.read && <Badge dot />}
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <Card>No Unread messages found in this page</Card>
                  )
                }
              </div>
            </div>
          )}
        </div>
      </div>
      {filteredRecords.length > 0 && (
        <Pagination
          pageSize={pageSize}
          onChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          showSizeChanger={true}
          total={total}
          current={currentPage}
          className="fixed-pagination"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      )}
    </>
  );
};

export default NotificationsPage;
const badgeStyle: React.CSSProperties = {
  backgroundColor: 'red',
};
