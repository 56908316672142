import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Layout } from 'antd';
import PageHeader from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { BreadcrumbProvider } from '../Context';
import Breadcrumb from './Breadcrumb';
import AdminTopBar from './AdminTopBar';
import { NotificationProvider } from '../Context/NotificationContext';
const { Content, Sider } = Layout;
interface CommonLayoutProps {
  fullHeight?: boolean;
}
const CommonLayout: React.FC<CommonLayoutProps> = ({ fullHeight }) => {
  const [startMargin, setStartMargin] = useState(300);
  const userRole = sessionStorage.getItem('userRole');
  const handleOnCollapse = (isCollapsed: boolean) => {
    setStartMargin(isCollapsed ? 70 : 285);
  };
  return (
    <div className="page-bg-left">
      <div className="page-bg-right">
        <Layout className="main-layout">
          <NotificationProvider>
            <Sidebar />
            {/* 
          onCollapse={handleOnCollapse} 
          <Layout
              style={
                {
                  marginInlineStart: startMargin,
                  maxHeight: '100vh',
                  transition: '100ms linear',
                  overflowY: 'auto',
                }
              }
            > */}
            <Content
              style={{
                maxHeight: '100vh',
                overflowY: 'auto',
                padding: fullHeight ? '16px 16px 0' : '16px', //as the layout of askme extents to bottom
              }}
            >
              <BreadcrumbProvider>
                {
                  //<Breadcrumb/>
                }
                <Outlet />
              </BreadcrumbProvider>
            </Content>
            {/* </Layout> */}
          </NotificationProvider>
        </Layout>
      </div>
    </div>
  );
};
export default CommonLayout;
