import api from '../api';
import { bestCallApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};
export const getCallDepartments = () => {
  const { getCallDepartmentList: bestCallListConfig } = bestCallApi;
  return handleApiCall(bestCallListConfig);
};
//
export const getCallsByDepartment = (body: any) => {
  const { getCallList: bestCallListConfig } = bestCallApi;
  const apiConfig = { ...bestCallListConfig, body };
  return handleApiCall(apiConfig);
};
export const searchCalls = (data: any, cancelToken: any) => {
  const { searchTerm, departmentName } = data;
  const { searchCall: searchCallConfig } = bestCallApi;
  const apiConfig = {
    ...searchCallConfig,
    ...{
      api: `/api/spoonfeed/get-best-calls-by-key-note?department_name=${departmentName}&search_term=${searchTerm}`,
    },
  };
  return handleApiCall({ ...apiConfig, cancelToken });
};
export const getCallById = (body: any) => {
  const { getCallById: bestCallListConfig } = bestCallApi;
  const apiConfig = { ...bestCallListConfig, body };
  return handleApiCall(apiConfig);
};
export const updateElapsedTime = (body: any) => {
  const { updateTime: updateTimeConfig } = bestCallApi;
  const apiConfig = { ...updateTimeConfig, body };
  return handleApiCall(apiConfig);
};
