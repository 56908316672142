import api from "../api";
import { knowledgeBaseApi } from "../apiVariables";

const handleApiCall = (apiConfig: any) => {
    return new Promise((resolve, reject) => {
        api(apiConfig)
            .then(resolve)
            .catch(reject);
    });
};

export const getKnowledgeBaseCategories = () => {
    const { getKnowledgeBaseCategories: knowledgeBaseCategoriesConfig } = knowledgeBaseApi;
    return handleApiCall(knowledgeBaseCategoriesConfig);
};

export const getKnowledgeBaseDetails = (categoryName: string) => {
    const { getKnowledgeBaseDetails: knowledgeBaseDetailsConfig } = knowledgeBaseApi;
    const apiConfig = { ...knowledgeBaseDetailsConfig, api: `/api/equentis/get_knowledge_base_topics?category=${categoryName}` };
    return handleApiCall(apiConfig);
};

