// CourseCard.js
import React from 'react';
import { Card, Typography, Flex } from 'antd';
import AssesmentCard from './AssesmentCard';
const { Text, Paragraph } = Typography;
interface CertificatePros {
  isLock: boolean;
  id: string;
  onAssesmentCardClick: (assesmentId: string) => any;
}
const CertificateCard = ({
  onAssesmentCardClick,
  isLock,
  id,
}: CertificatePros) => {
  return (
    <section style={{ marginBottom: '10px' }}>
      <Typography.Title
        className="page-sub-heading"
        level={5}
        style={{ marginBottom: 0 }}
      >
        Certificate
      </Typography.Title>
      <>
        {isLock && ( // if any assessment is locked
          <Paragraph italic type="secondary" style={{ fontSize: '12px' }}>
            Your certificate will be available for download once your assignment
            has been reviewed.
          </Paragraph>
        )}
        <Flex
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            marginTop: '20px',
          }}
        >
          <AssesmentCard
            name={isLock ? 'Certificate' : 'Download'}
            id={id}
            isLocked={isLock}
            className={isLock ? '' : 'active'}
            //isComplete={assesment.is_attempted}// fixme complete
            onAssesmentCardClick={(assesmentId) =>
              onAssesmentCardClick(assesmentId)
            }
            imgSrc={
              isLock ? '/images/assesment-lock.png' : '/images/certificate.png'
            }
          />
        </Flex>
      </>
    </section>
  );
};

export default CertificateCard;
