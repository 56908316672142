import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge } from 'antd';
import ReactPlayer from 'react-player';
import PriorityBadge from './PriorityBadge';
import ArticleTitle from './ArticleTitle';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
const { Title, Text, Paragraph } = Typography;

interface RecordItemProps {
  playingVideoId: string | null;
  item: any;
  selectedTab: string;
  isNewsletter?: boolean;
  priority?: 'oldest' | 'newest' | 'mostrelevant';
  onReadMoreClick: (title: string, url: string) => void;
  onVideoPlay: (isPlaying: string | null) => any;
}

const RecordItem: React.FC<RecordItemProps> = ({
  item,
  selectedTab,
  onReadMoreClick,
  isNewsletter,
  priority,
  playingVideoId,
  onVideoPlay,
}) => {
  useEffect(() => {
    return () => {
      onVideoPlay(null); // reset on page change
    };
  }, []);
  const videoId = `video-${item.id}`;
  const [currentPriority, setCurrentPriority] = useState(() => {
    // return sessionStorage.getItem('priority') as 'oldest' | 'newest' | 'mostrelevant' || priority;
  });
  // console.log("sessionstorage",priority)
  // const handlePriorityClick = (newPriority: 'oldest' | 'newest' | 'mostrelevant') => {
  //   setCurrentPriority(newPriority);
  //   sessionStorage.setItem('priority', newPriority);
  // };
  // useEffect(() => {
  //   localStorage.setItem('priority', priority || '');
  // }, [currentPriority]);
  if (selectedTab === 'video') {
    let badgeColor = '';
    if (item.priority && item.priority == 'oldest') {
      badgeColor = '#F0AD4E';
    } else if (item.priority && item.priority == 'newest') {
      badgeColor = '#5CB85C';
    } else if (item.priority && item.priority == 'mostrelevant') {
      badgeColor = '#D9534F';
    }

    return item.video_link &&
      ((item.priority && priority && item.priority == priority) ||
        !item.priority ||
        !priority) ? (
      <div style={{ marginBottom: '25px' }}>
        {isNewsletter ? (
          <ArticleTitle
            title={`${item.news_letter_summary?.title} | ${new Date(
              item.date
            ).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}`}
          />
        ) : (
          <ArticleTitle
            priority={item.priority}
            title={item.title}
            date={item.date}
          />
        )}
        <VideoPlayer
          id={item.id}
          videoURL={item.video_link}
          playingVideoId={playingVideoId}
          onVideoPlay={(videoId: string | null) => onVideoPlay(videoId)}
        />
      </div>
    ) : (
      <></>
    );
  }

  if (selectedTab === 'pdf') {
    return item.pdf &&
      ((item.priority && priority && item.priority == priority) ||
        !item.priority ||
        !priority) ? (
      <>
        <Card style={{ marginTop: '10px' }}>
          <h2 style={{ marginTop: 0, fontWeight: 500 }}>{item.title}</h2>
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              marginTop: 0,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.description}
          </Paragraph>
          <Flex>
            <div style={{ flexGrow: '1', color: '#737272' }}>
              <Text italic>
                {new Date(item.date).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </div>
            <div>
              <Text
                style={{ color: '#001E5A', cursor: 'pointer read-more' }}
                strong
                onClick={() =>
                  item.pdf?.url && onReadMoreClick(item.title, item.pdf?.url)
                }
              >
                Read more...
              </Text>
            </div>
          </Flex>
        </Card>
      </>
    ) : (
      <></>
    );
  }
  if (isNewsletter) {
    //news letter has different layout
    if (selectedTab === 'news_letter_summary') {
      /*if(!item.news_letter_summary){
        return <>No items found</>
      }*/
      return (
        <Card style={{ marginTop: '10px' }}>
          <div>
            <h2 style={{ marginTop: 0, fontWeight: 500 }}>
              {item.news_letter_summary?.title} |{' '}
              {new Date(item.date).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
          </div>
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              marginTop: 0,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.news_letter_summary?.description}
          </Paragraph>
          <div style={{ textAlign: 'right' }}>
            <Text
              style={{ color: '#001E5A', cursor: 'pointer read-more' }}
              strong
              onClick={() =>
                item.news_letter_summary.pdf?.url &&
                onReadMoreClick(
                  item.news_letter_summary.title,
                  item.news_letter_summary.pdf?.url
                )
              }
            >
              Read more...
            </Text>
          </div>
        </Card>
      );
    }
    return (
      <Card style={{ marginTop: '10px' }}>
        <div>
          <h2 style={{ marginTop: 0, fontWeight: 500 }}>
            {item.title} |{' '}
            {new Date(item.date).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </h2>
        </div>

        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            marginTop: 0,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {item.description}
        </Paragraph>
        <div style={{ textAlign: 'right' }}>
          <Text
            style={{ color: '#001E5A', cursor: 'pointer read-more' }}
            strong
            onClick={() =>
              item.pdf?.url && onReadMoreClick(item.title, item.pdf?.url)
            }
          >
            Read more...
          </Text>
        </div>
      </Card>
    );
  }
  return (
    <Card style={{ marginTop: '10px' }}>
      <h2 style={{ marginTop: 0, fontWeight: 500 }}>{item.title}</h2>
      <Paragraph
        ellipsis={{ rows: 2 }}
        style={{
          marginTop: 0,
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {item.description}
      </Paragraph>
      <Flex>
        <div style={{ flexGrow: '1', color: '#737272' }}>
          <Text italic>
            {new Date(item.date).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Text>
        </div>
        <div>
          <Text
            style={{ color: '#001E5A', cursor: 'pointer read-more' }}
            strong
            onClick={() =>
              item.pdf?.url && onReadMoreClick(item.title, item.pdf?.url)
            }
          >
            Read more...
          </Text>
        </div>
      </Flex>
    </Card>
  );
};

export default RecordItem;
