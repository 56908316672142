// src/components/NotificationsPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Flex,
  Typography,
  Button,
  Badge,
  MenuProps,
  message,
  Pagination,
} from 'antd';
import {
  getNotifications,
  readAllNotification,
  readSingleNotification,
} from '../services/ApiActions/newsletterAct';
import { defaultPageSize, formatDate } from '../helper/UtilityMethods';
import Loader from '../components/Layout/Loader';
import { useNotification } from '../components/Context/NotificationContext';
import PageHeading from '../components/Layout/PageHeading';
import DateTitle from '../components/Content/DateTitle';

const { Title, Text, Paragraph } = Typography;

const NotificationsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [records, setRecords] = useState<any[] | undefined>();
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [id, setId] = useState<number | undefined>(undefined);
  const notificationStatus = searchParams.get('status');
  const { updateType } = useParams<{ updateType?: string }>();
  const navigate = useNavigate();

  const { unreadCount, setUnreadCount, recentNotifications } =
    useNotification();

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  //const [universalUnread, setUniversalUnread] = useState(unreadCount);

  const [showingUnread, setShowingUnread] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        !records && setIsLoading(true);

        const status =
          notificationStatus && notificationStatus === 'unread'
            ? false
            : undefined;

        const response: any = await getNotifications(
          status,
          currentPage,
          pageSize
        );
        setIsLoading(false);

        if (response.status === 'success') {
          setRecords(response.data.results);
          // if (showingUnread) {
          //   // Filter unread notifications
          //   const unreadNotifications = response.data.results.filter(
          //     (notification: any) => !notification.read
          //   );
          //   setFilteredRecords(unreadNotifications);
          // } else {
          //   setFilteredRecords(response.data.results);
          // }
          setFilteredRecords(response.data.results);
          setTotal(
            response.data.pagination?.total || response.data.results.length
          );
          // Calculate unread count based on fetched data
          // setUnreadCount(response.data.results.filter((notification: any) => !notification.read).length);
          //setUniversalUnread(unreadCount);
        } else {
          console.error(
            'Failed to fetch:',
            response.message || 'Unknown error'
          );
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [showingUnread, notificationStatus, currentPage, pageSize]); // Add showingUnread to dependency array

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleShowSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const readAllNotificationMark = async () => {
    if (unreadCount <= 0) {
      message.info(`No unread messages found`);
      return;
    }
    try {
      const readNotification: any = await readAllNotification();
      if (readNotification?.status === 'success') {
        message.success(`Marked all notifications as 'read' successfully`);
        setUnreadCount(unreadCount > 0 ? unreadCount - 1 : 0);
        //setUniversalUnread(0);
        if (notificationStatus && notificationStatus === 'unread') {
          navigate('/user-notification');
        } else {
          const newFilteredRecords = [...filteredRecords];
          const updatedFilteredRecords = newFilteredRecords.map((record) => {
            record.read = true;
            return record;
          });
          setFilteredRecords(updatedFilteredRecords);
        }
      } else {
        message.error(`Something went wrong`);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = async (notification: any) => {
    if (notification.read) {
      return;
    }
    try {
      const markRead: any = await readSingleNotification(notification.id);

      console.log(markRead.status);
      if (markRead?.status === 'success') {
        const newFilteredRecords = [...filteredRecords];
        const updatedFilteredRecords = newFilteredRecords.map((record) => {
          if (record.id == notification.id) {
            record.read = true;
          }
          return record;
        });
        setFilteredRecords(updatedFilteredRecords);
        setUnreadCount(unreadCount > 0 ? unreadCount - 1 : 0);
        //setUniversalUnread(universalUnread - 1);
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  if (isLoading || !recentNotifications) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <>
      <div className="notifications-page">
        {contextHolder}
        <PageHeading heading="Notifications" />

        <div className="notification-spacing card-content-center  max-width">
          {recentNotifications?.length === 0 ? (
            <div className="notification-spacing">
              <div className="empty-notifications">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Flex justify="center" align="center" vertical={true}>
                    <img src="/images/icons/Vector.png" alt="Bell Icon" />
                    <div
                      className="heading"
                      style={{ fontSize: '24px', fontWeight: 500 }}
                    >
                      No Notifications Yet!
                    </div>
                    <div style={{ fontSize: '18px', fontWeight: 400 }}>
                      When you get notifications, they’ll show up here.
                    </div>
                  </Flex>
                </div>
              </div>
            </div>
          ) : (
            <div className="notification-ui">
              <Flex className="notifications-header" gap={16}>
                <Flex gap={16} className="">
                  <Button
                    className={`tab-button-noti tab-button ${
                      !(
                        notificationStatus && notificationStatus === 'unread'
                      ) && 'active'
                    }`}
                    onClick={() => {
                      setShowingUnread(false);
                      navigate('/user-notification');
                    }}
                  >
                    All
                  </Button>
                  <Button
                    className={`tab-button-noti tab-button ${
                      notificationStatus &&
                      notificationStatus === 'unread' &&
                      'active'
                    }`}
                    onClick={() => {
                      setShowingUnread(true);
                      navigate('/user-notification?status=unread');
                    }}
                  >
                    Unread{' '}
                    <Badge
                      style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      count={unreadCount}
                    />
                  </Button>
                </Flex>
                <Button
                  type="link"
                  style={{ marginLeft: 'auto', fontSize: '13px' }}
                  onClick={() => readAllNotificationMark()}
                >
                  Mark all read
                </Button>
              </Flex>
              <div
                style={{
                  height: 'calc(100vh - 170px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  padding: '10px',
                  marginBottom: '10px',
                }}
              >
                {
                  //<Badge style={{paddingLeft: '10px', paddingRight: '10px'}} count={1} />
                  filteredRecords.length > 0 ? (
                    <div
                      className="notification-card"
                      //style={{ height: 'calc(100vh - 150px)' }}
                    >
                      {filteredRecords.map((notification) => (
                        <Card
                          key={notification.id}
                          style={{
                            backgroundColor: !notification.read
                              ? '#F0E2D5'
                              : 'white',
                          }}
                          onClick={() => handleNotificationClick(notification)}
                          className={`notification-item ${
                            !notification.read ? 'unread' : ''
                          }pointer`}
                        >
                          <Flex style={{ width: '100%' }}>
                            <Paragraph
                              style={{
                                fontSize: '14px',
                                fontWeight: '300',
                                flexGrow: 1,
                              }}
                            >
                              {notification.message}
                            </Paragraph>
                            {!notification.read && <Badge dot />}
                          </Flex>

                          <div>
                            <Text
                              style={{ color: '#737272', fontSize: '11px' }}
                            >
                              {formatDate(notification.createdAt)}
                            </Text>{' '}
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <Card>No Unread messages found</Card>
                  )
                }
              </div>
            </div>
          )}
        </div>
      </div>
      {filteredRecords.length > 0 && (
        <Pagination
          pageSize={pageSize}
          onChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          showSizeChanger={true}
          total={total}
          current={currentPage}
          //className="fixed-pagination"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      )}
    </>
  );
};
export default NotificationsPage;
