import api from '../api';
import { lmsApi, reviewApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};
export const getCourseById = (courseId: string, moduleId: string) => {
  ///api/spoonfeed/lms/chapters/?courseId=1&moduleId=2
  const { getCourseById: courseByIdConfig } = lmsApi;
  const apiConfig = {
    ...courseByIdConfig,
    api: `/api/spoonfeed/lms/chapters/?courseId=${courseId}&moduleId=${moduleId}`,
  };
  return handleApiCall(apiConfig);
};
export const getAllModulesByCourseId = (courseId: string) => {
  const { getCourseById: courseByIdConfig } = lmsApi;
  const apiConfig = {
    ...courseByIdConfig,
    api: `/api/spoonfeed/lms/modules/${courseId}`,
  };
  return handleApiCall(apiConfig);
};
export const getAllCourses = () => {
  const { getAllCourses: allCoursesConfig } = lmsApi;
  const apiConfig = { ...allCoursesConfig, api: `/api/spoonfeed/lms/courses` };
  return handleApiCall(apiConfig);
};
export const updateElapsedTime = (body: any) => {
  const { updateTime: updateTimeConfig } = lmsApi;
  const apiConfig = { ...updateTimeConfig, body };
  return handleApiCall(apiConfig);
};
export const submitQuizAnswers = (body: any) => {
  const { submitQuizAnswers: submitQuizAnswersConfig } = lmsApi;
  const apiConfig = { ...submitQuizAnswersConfig, body };
  return handleApiCall(apiConfig);
};
export const getLastVideo = (courseId: string, userId: string) => {
  const { lastVideoInfo: lastVideoInfoConfig } = lmsApi;
  const apiConfig = {
    ...lastVideoInfoConfig,
    api: `/api/custom/all/learning-management-system/last-video/courses/${courseId}/users/${userId}`,
  };
  return handleApiCall(apiConfig);
};
export const getVideoDetails = (userId: string, videoId: string) => {
  const { videoDetailsInfo: videoDetailsInfoConfig } = lmsApi;
  const apiConfig = {
    ...videoDetailsInfoConfig,
    api: `/api/custom/all/learning-management-system/videodetails/users/${videoId}/users/${userId}`,
  };
  return handleApiCall(apiConfig);
};
export const getQuestionByVideoId = (videoId: string) => {
  const { getQuestionByVideoId: getQuestionByVideoIdConfig } = lmsApi;
  const apiConfig = {
    ...getQuestionByVideoIdConfig,
    api: `/api/spoonfeed/lms/quiz-questions/get-quiz/${videoId}`,
  };
  return handleApiCall(apiConfig);
};
export const saveQuestionResponse = (body: any) => {
  const { saveQuestionResponse: saveQuestionResponseConfig } = lmsApi;
  const apiConfig = { ...saveQuestionResponseConfig, body };
  return handleApiCall(apiConfig);
};
export const saveAssesmentResponse = (body: any) => {
  const { saveAssesmentResponse: saveAssesmentResponseConfig } = lmsApi;
  const apiConfig = { ...saveAssesmentResponseConfig, body };
  return handleApiCall(apiConfig);
};
export const getAssesmentQuestions = (
  assessmentId: string,
  courseId: string
) => {
  const { getAssesmentQuestions: assesmentQuestionsConfig } = lmsApi;
  const apiConfig = {
    ...assesmentQuestionsConfig,
    api: `/api/spoonfeed/lms/assessment/assessment-questions/${assessmentId}/${courseId}`,
  };
  return handleApiCall(apiConfig);
};
export const getCertificate = (courseId: string) => {
  const { getCertificate: certificateConfig } = lmsApi;
  const apiConfig = {
    ...certificateConfig,
    api: `/api/spoonfeed/lms/generate-certificate/course/${courseId}`,
  };
  return handleApiCall(apiConfig);
};

export const getAchievements = () => {
  const { getAchievements: achievementConfig } = lmsApi;
  const apiConfig = {
    ...achievementConfig,
    api: `/api/spoonfeed/lms/user-achievements`,
  };
  return handleApiCall(apiConfig);
};
// api/spoonfeed/lms/assessment-result/quiz-result-by-chapter-id/user/2/chapter/10
