import React, { useState } from 'react';
import {
  Form,
  Radio,
  Checkbox,
  Space,
  Row,
  Col,
  Card,
  Button,
  GetProp,
  RadioChangeEvent,
  Typography,
} from 'antd';
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'; // Import the icon
const { Text, Title } = Typography;
type QuizProps = {
  videoId: string;
  moduleId: string;
  courseId: string;
  isModalLoading: boolean;
  isAttempted: boolean;
  isQuizSubmitted: boolean;
  processingForm: boolean;
  onSubmitQuiz: (
    data: any,
    videoId: string,
    courseId: string,
    moduleId: string
  ) => any;
  onNextClick: (videoId: string) => any;
  quizQuestion: any;
  isAllCorrect: boolean;
  isLastChapterOfCouse?: boolean;
  isLastModuleChapter?: boolean;
  isSubmitDisabled?: boolean;
  onValueChange: (formItems: any) => any;
};
const Quiz: React.FC<QuizProps> = ({
  quizQuestion,
  isQuizSubmitted,
  videoId,
  moduleId,
  courseId,
  onSubmitQuiz,
  onNextClick,
  isAttempted,
  processingForm,
  isAllCorrect,
  isLastChapterOfCouse,
  isLastModuleChapter,
  isSubmitDisabled,
  onValueChange,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const formValues = [];

    for (const [key, value] of Object.entries(values)) {
      formValues.push({
        submitted_option: value,
        question_id: Number(key.replace('question-', '')),
      });
    }
    const payload = {
      responses: formValues,
      chapter_id: Number(videoId),
      course_id: Number(courseId),
      module_id: Number(moduleId),
      is_last: false,
    };
    onSubmitQuiz(payload, videoId, courseId, moduleId);
  };
  const getOptionClassname = (
    is_correct: boolean,
    optionIndex: number,
    submitted_option: number = 0
  ) => {
    if (
      typeof is_correct === 'undefined' ||
      optionIndex !== submitted_option - 1
    )
      return null;
    return is_correct
      ? { className: 'correct-answer', text: '(Correct Answer)' }
      : { className: 'incorrect-answer', text: '(Wrong Answer)' };
  };
  if (isAttempted) {
    return null;
  }
  return (
    <Form
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 600 }}
      onFieldsChange={(_, allFields) => {
        onValueChange(allFields);
      }}
    >
      {quizQuestion?.length > 0 && (
        <>
          <Title
            className="page-sub-heading"
            level={5}
            style={{ marginBottom: 0 }}
          >
            Questions:
          </Title>
          {quizQuestion.map((question: any, index: number) => {
            console.log(question);
            const {
              id,
              questionId,
              question: questionText,
              options,
              submitted_option,
              is_correct,
              correct_answer,
            } = question;
            const defaultOption = submitted_option;
            const OptionComponent = Radio.Group;
            const Component = Radio;
            const answerIndex = correct_answer - 1;

            return (
              <div key={id || questionId}>
                <p>
                  {index + 1}. {questionText}
                </p>
                <div
                  className="quiz-question"
                  style={{
                    marginBottom: '30px',
                    paddingLeft: '15px',
                    listStyleType: 'none',
                  }}
                >
                  <Form.Item
                    style={{ marginBottom: '10px' }}
                    name={`question-${id || questionId}`}
                    rules={[{ required: true, message: 'Select your answer' }]}
                  >
                    <OptionComponent defaultValue={defaultOption}>
                      {options.map((option: any, optionIndex: number) => {
                        const answer = getOptionClassname(
                          is_correct,
                          optionIndex,
                          submitted_option
                        );
                        return (
                          <div
                            key={optionIndex}
                            style={{ marginBottom: '10px' }}
                          >
                            <Component
                              className={answer?.className}
                              value={optionIndex + 1}
                            >
                              {option.option} {answer?.text}
                            </Component>
                            <br />
                          </div>
                        );
                      })}
                    </OptionComponent>
                  </Form.Item>
                  {typeof is_correct !== 'undefined' && (
                    <div style={{ marginBottom: '20px' }}>
                      {is_correct ? (
                        <Text className="correct-answer">
                          <CheckOutlined /> Correct!
                        </Text>
                      ) : (
                        <Text className="incorrect-answer">
                          <CloseOutlined /> Wrong!
                        </Text>
                      )}
                    </div>
                  )}

                  {typeof is_correct !== 'undefined' &&
                    !is_correct &&
                    !processingForm &&
                    answerIndex >= 0 && (
                      <div>
                        *Correct answer is{' '}
                        <span>"{options[answerIndex].option}"</span> .
                      </div>
                    )}
                </div>
              </div>
            );
          })}
          {
            //(!isLastChapterOfCouse)
            //&&
            <div>
              {isQuizSubmitted && isAllCorrect ? (
                <Button type="primary" onClick={() => onNextClick(videoId)}>
                  {isLastModuleChapter ? (
                    <>
                      <ArrowLeftOutlined /> Back to course
                    </>
                  ) : (
                    <>
                      Watch Next Video <ArrowRightOutlined />
                    </>
                  )}
                </Button>
              ) : (
                <Form.Item>
                  <Button
                    loading={processingForm}
                    disabled={isSubmitDisabled}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              )}
            </div>
          }
        </>
      )}
    </Form>
  );
};

export default Quiz;
