import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Typewriter from 'typewriter-effect';

interface TextMessage {
  message: string;
  animate: boolean;
  hasContent: boolean;
  onTypingStatusChange: (status: boolean) => any;
}
const TextMessage: React.FC<TextMessage> = ({
  message,
  animate,
  onTypingStatusChange,
  hasContent,
}) => {
  return animate ? (
    <Typewriter
      options={{ cursor: '', delay: 5 }}
      onInit={(typewriter) => {
        typewriter
          .typeString(message)
          .callFunction(() => {
            onTypingStatusChange(false);
          })
          .start();
      }}
    />
  ) : (
    <Markdown className="markdown-content" rehypePlugins={[rehypeRaw]}>
      {hasContent ? message : 'Please try different question'}
    </Markdown>
  );
};

export default TextMessage;
