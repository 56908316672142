import React from 'react';
import { Layout, Card, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

const NoModulesPage = () => {
  return (
    <Layout>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            textAlign: 'center',
          }}
        >
          <Card>
            <Title level={2}>🔒</Title>
            <Title level={4}>No modules available</Title>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default NoModulesPage;
