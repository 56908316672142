import axios, { AxiosResponse, CancelToken, ResponseType } from 'axios';
import Authorization from '../helper/Authorization';
import { redirect } from 'react-router-dom';
import { router } from '../App';

export type ApiRequestType = 'get' | 'post' | 'put' | 'delete';

interface APIType {
  api: string;
  method: ApiRequestType;
  baseurl?: string;
  body?: any;
  auth?: boolean;
  isPublic?: boolean;
  cancelToken?: CancelToken; // Add cancelToken property
  responseType?: ResponseType; // Add cancelToken property
}

const axiosInstance = axios.create({});

const api = async ({
  api,
  method = 'get',
  baseurl = 'baseurl',
  isPublic = false,
  auth = true,
  body,
  cancelToken, // Accept cancel token as a parameter
  responseType,
}: APIType) => {
  return await new Promise((resolve, reject) => {
    //get content using stapi's in-build api
    //axiosInstance.defaults.headers.Authorization = `Bearer REACT_APP_CMS_TOKEN`;
    //axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    //axiosInstance.defaults.headers['Accept'] = 'application/pdf';
    if (responseType) {
      axiosInstance.defaults.responseType = responseType;
    }

    //!isPublic
    if (!isPublic) {
      const token = sessionStorage.getItem('token');
      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      axiosInstance.defaults.headers.Authorization = '';
    }
    (
      axiosInstance[method] as (
        url: string,
        data?: any,
        config?: any
      ) => Promise<AxiosResponse>
    )(`${getApiBaseUrl(baseurl)}${api}`, body, {
      cancelToken,
    })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((error: any) => {
        if (error.code == 'ERR_CANCELED') {
          return;
        }
        if (error.response?.status && error.response?.status === 401) {
          //Authorization.logout();
          console.log('$router', router);
          //router.navigate('', { replace: true })
        } else if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

const getApiBaseUrl = (type: string) => {
  switch (type) {
    case 'cmsbaseurl':
      return `${process.env.REACT_APP_CMS_BASE_URL}`;
    case 'baseurl':
      return `${process.env.REACT_APP_CMS_BASE_URL}`;
    case 'notificationurl':
      return `${process.env.REACT_APP_CMS_BASE_URL_NOTIFICATION}`;
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};

export default api;
