import React, { useState } from 'react';
import {
  Typography,
  Modal,
  Card,
  Layout,
  MenuProps,
  Menu,
  ConfigProvider,
  Space,
  Flex,
  Pagination,
} from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import Loader from './Loader';
import { FilterBar } from '../Header/FilterBar';
import RecordsList from '../Content/RecordList';
import EmptyRecord from '../Content/EmptyRecord';
import PageHeading from './PageHeading';
import PdfViewer from '../PDF/PDFViewer';

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

type MenuItem = Required<MenuProps>['items'][number];
//export type RangeValueType<DateType> = [start: DateType | null | undefined, end: DateType | null | undefined];
interface ArticleLayoutProps {
  pagination?: React.ReactNode;
  tabs: any;
  title: string;
  records: any[];
  selectedMenu?: any;
  menuItems: any;
  isLoading?: boolean;
  defaultDateRange?: any;
  selectedTab: any;
  onRangeChange?: any;
  onPriorityChange?: any;
  priority?: any;
  onAllClick: any;
  onTabClick: any;
  isNewsletter?: boolean;
  showPriorityFilter?: boolean;
  tabItemsCount?: any;
  showDateRange?: boolean;
}
const ArticleLayout: React.FC<ArticleLayoutProps> = ({
  pagination,
  tabs,
  title,
  records,
  selectedMenu,
  menuItems,
  isLoading,
  defaultDateRange,
  onRangeChange,
  onAllClick,
  selectedTab,
  onTabClick,
  isNewsletter,
  onPriorityChange,
  priority,
  showPriorityFilter,
  tabItemsCount,
  showDateRange,
}) => {
  const [open, setOpen] = useState(false);
  const [PDFURL, setPDFURL] = useState('');
  const [PDFTitle, setPDFTitle] = useState('');
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  //const [selectedTab, setSelectedTab] = useState('pdf');

  const dateFormat = 'DD-MM-YYYY';
  const headerHeight = '106px';
  const rangePresets = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    {
      label: 'This Month',
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
      label: 'Last Month',
      value: [
        dayjs().add(-1, 'M').startOf('month'),
        dayjs().add(-1, 'M').endOf('month'),
      ],
    },
  ];

  const onReadMoreClick = (artitleHeading: string, pdfURL: string) => {
    setOpen(true);
    setPDFURL(pdfURL);
    setPDFTitle(artitleHeading);
  };
  const handleOnVideoPlay = (isPlaying: string | null) => {
    setPlayingVideoId(isPlaying);
  };
  return (
    <>
      <PageHeading heading={title} />
      <Layout>
        <Sider
          width={220}
          style={{
            backgroundColor: 'transparent',
            maxHeight: `calc(100vh - ${headerHeight})`,
            overflow: 'auto',
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemSelectedBg: '#001e5a',
                  itemSelectedColor: '#FFF',
                },
              },
            }}
          >
            <Menu
              key={Math.random()}
              className="secondary-menu"
              defaultSelectedKeys={[selectedMenu]}
              style={{
                backgroundColor: 'transparent',
                height: '100%',
                borderRight: 0,
              }}
              items={menuItems}
            />
          </ConfigProvider>
        </Sider>
        <Content
          style={{
            marginLeft: '20px',
            //width: '100%', //to fix expanding of container

            height: `calc(100vh - ${headerHeight})`,
          }}
        >
          {/*conditionally hide tabs*/}
          {FilterBar({
            tabs: selectedMenu == 'submenu-performance-report' ? [] : tabs,
            selectedTab,
            onTabClick,
            onAllClick,
            rangePresets,
            defaultDateRange,
            onRangeChange,
            dateFormat,
            onPriorityChange,
            priority,
            showPriorityFilter,
            showDateRange,
          })}
          {isLoading ? (
            <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
          ) : (
            <>
              <div
                style={{
                  fontWeight: '500',
                  paddingRight: '15px',
                  height: 'calc(100vh - 190px)',
                  overflowY: 'auto',
                }}
              >
                {records.length > 0 ? (
                  <>
                    <RecordsList
                      playingVideoId={playingVideoId}
                      tabs={tabs}
                      priority={priority}
                      isNewsletter={isNewsletter}
                      records={records}
                      selectedTab={selectedTab}
                      onReadMoreClick={onReadMoreClick}
                      onVideoPlay={(isPlaying) => handleOnVideoPlay(isPlaying)}
                    />
                  </>
                ) : (
                  <EmptyRecord />
                )}
              </div>
              {pagination}
            </>
          )}

          <Modal
            title={PDFTitle}
            footer={null}
            centered
            open={open}
            onCancel={() => setOpen(false)}
            width={1200}
          >
            <PdfViewer fileUrl={PDFURL} />
          </Modal>
        </Content>
      </Layout>
    </>
  );
};

export default ArticleLayout;
