import React, { useEffect, useState } from 'react';
import { getAchievements, getCertificate } from '../services/ApiActions/lmsAct';
import {
  message,
  Typography,
  Row,
  Col,
  Card,
  Button,
  Image,
  Empty,
} from 'antd';
import { error } from 'console';
import Loader from '../components/Layout/Loader';
import PageHeading from '../components/Layout/PageHeading';

const AchievementsPage = () => {
  const [loading, setLoading] = useState(false);
  const [certificates, setCertificates] = useState<any[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchAchievements = async () => {
      setLoading(true);
      try {
        const response: any = await getAchievements();
        console.log('API Response:', response);

        if (response.status === 'success' && response.data) {
          setCertificates(response.data);
        } else {
          messageApi.error('Failed to fetch certificates');
        }
      } catch (error) {
        console.error('Error fetching achievements:', error);
        messageApi.error('Something went wrong. Please try later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAchievements();
  }, []);

  const downloadCertificate = (pdfUrl: string) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = pdfUrl.split('/').pop() || 'certificate.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }

  return (
    <div>
      {contextHolder}
      <PageHeading heading="Achievements" />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        {certificates.length > 0 ? (
          certificates.map((certificate: any) => (
            <div style={{ width: '250px', marginBottom: '5px' }}>
              <Image
                alt="Certificate"
                src={certificate.certificate_image}
                style={{
                  width: '100%',
                  height: 'auto',
                  boxShadow: '0px 0.74px 0.74px 0px #0000001A',
                }}
              />
              <Typography.Title
                className="ellipsis-text heading"
                level={4}
                style={{
                  marginTop: '5px',
                  marginBottom: '8px',
                  fontSize: '24px',
                }}
              >
                {certificate.course_id
                  ? certificate.course_id.name
                  : 'Unknown Course'}
              </Typography.Title>

              <Button
                type="primary"
                onClick={() => downloadCertificate(certificate.certificate_pdf)}
              >
                Download Certificate
              </Button>
            </div>
          ))
        ) : (
          <Empty
            style={{
              marginTop: '5em',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text>No certificates available.</Typography.Text>
            }
          />
        )}
      </div>
    </div>
  );
};

export default AchievementsPage;
