import { message as Toaster } from "antd"
import { NoticeType } from "antd/es/message/interface";

type ToastProps = {
    type?: NoticeType,
    message: string
}

const Toast = ({ type = "success", message }: ToastProps) => {
    Toaster[type](message);
}

export default Toast;