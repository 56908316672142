import React from 'react';
import { Card, Typography, Empty } from 'antd';

const { Text } = Typography;

interface EmptyRecordProps {
  message?: string;
  style?: React.CSSProperties;
}

const EmptyRecord: React.FC<EmptyRecordProps> = ({
  message = 'No items found',
  style = {},
}) => {
  return (
    <Card style={{ marginTop: '20px', ...style }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Typography.Text>{message}</Typography.Text>}
      />
    </Card>
  );
};

export default EmptyRecord;
