// CourseCard.js
import React from 'react';
import { Card, Typography, Flex } from 'antd';
import AssesmentCard from '../LMSComponent/AssesmentCard';

const { Text, Paragraph } = Typography;
interface AssesmentListPros {
  assessments: any[];
  isLock: boolean;
  onAssesmentCardClick: (assesmentId: string) => any;
}
const AssesmentList = ({
  assessments,
  onAssesmentCardClick,
  isLock,
}: AssesmentListPros) => {
  return (
    <section style={{ marginTop: '20px' }}>
      <Typography.Title
        className="page-sub-heading"
        level={5}
        style={{ marginBottom: 0 }}
      >
        Assessments
      </Typography.Title>
      {assessments.length <= 0 ? (
        <Paragraph italic type="secondary" style={{ fontSize: '16px' }}>
          No assesment found for this course.
        </Paragraph>
      ) : (
        <>
          {
            // (assessments.find((assessment) => assessment.is_lock))// if any assessment is locked
            // &&
            // <Text italic type="secondary">Complete all the modules to unlock the assessments.</Text>
          }
          <Paragraph italic type="secondary" style={{ fontSize: '12px' }}>
            Complete all the modules to unlock the assessments.
          </Paragraph>
          <Flex
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
              marginTop: '20px',
            }}
          >
            {assessments.map(
              (assesment: {
                name: string;
                id: string;
                is_lock: boolean;
                is_attempted: boolean;
              }) => {
                return (
                  <AssesmentCard
                    name={assesment.name}
                    id={assesment.id}
                    isLocked={assesment.is_lock}
                    isComplete={assesment.is_attempted} // fixme complete
                    showToolTip
                    onAssesmentCardClick={(assesmentId) =>
                      onAssesmentCardClick(assesmentId)
                    }
                    imgSrc={
                      assesment.is_lock
                        ? '/images/assesment-lock.png'
                        : assesment.is_attempted
                        ? '/images/assesment-complete.png'
                        : '/images/assesment.png'
                    }
                  />
                );
              }
            )}
          </Flex>
        </>
      )}
    </section>
  );
};

export default AssesmentList;
