import React, { useState, useEffect } from 'react';
import { Card, Typography, Flex, Badge } from 'antd';
import ReactPlayer from 'react-player';
import ItemCard from './ListCard';
import VideoCard from './VideoCard';
const { Title, Text, Paragraph } = Typography;

interface ListItemProps {
  item: any;
  selectedTab: string;
  isNewsletter?: boolean;
  includeDateInHeading?: boolean;
  playingVideoId?: string | null;
  priority?: 'oldest' | 'newest' | 'mostrelevant';
  onReadMoreClick: (title: string, url: string) => void;
  onVideoPlay?: (isPlaying: string | null) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  item,
  selectedTab,
  onReadMoreClick,
  isNewsletter,
  includeDateInHeading,
  priority,
  onVideoPlay,
  playingVideoId,
}) => {
  if (selectedTab === 'video') {
    return (
      <VideoCard
        playingVideoId={playingVideoId || null}
        id={item.id}
        title={item.title}
        description={item.description}
        date={item.date}
        videoURL={item.videoURL}
        onVideoPlay={(isPlaying: string | null) =>
          onVideoPlay && onVideoPlay(isPlaying)
        }
      />
    );
  }
  return (
    <ItemCard
      title={item.title}
      description={item.description}
      date={item.date}
      pdfURL={item.pdfURL}
      onReadMoreClick={onReadMoreClick}
      includeDateInHeading={includeDateInHeading}
    />
  );
};

export default ListItem;
