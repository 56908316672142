import React, { useRef, useEffect } from 'react';

import ScrollToBottom, {
  useScrollToBottom,
  useSticky,
} from 'react-scroll-to-bottom';
import SenderAvatar from '../../components/Chat/SenderAvatar';
import VideoList from './VideoList';
import ImageList from './ImageList';
import TextMessage from './TextMessage';
import DetailedMessage from './DetailedMessage';
import DetailedMessageIcon from './DetailedMessageIcon';
import LoadingMessage from './LoadingMessage';
import Greeting from './Greeting';

interface ChatMessagesProps {
  chatMessages: any;
  animateId: string | null;
  isLoading: boolean;
  isInitialLoad: boolean;
  playingVideoId: string | null;
  toggleDetailedAnswer: (message: any) => any;
  onVideoPlay: (videoId: string | null) => any;
  toggleAutoSuggestInput: (message: any, status: boolean) => any;
}
const Content: React.FC<ChatMessagesProps> = ({
  chatMessages,
  toggleDetailedAnswer,
  toggleAutoSuggestInput,
  onVideoPlay,
  animateId,
  playingVideoId,
  isLoading,
  isInitialLoad,
}) => {
  const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();
  const ref: any = useRef();
  useEffect(() => {
    if (sticky) return;
    const shouldScroll =
      !ref.current || ref.current.length !== chatMessages.messages.length;
    if (shouldScroll) {
      scrollToBottom();
      ref.current = chatMessages.messages;
    }
  }, [chatMessages, scrollToBottom, sticky]);
  return (
    <div>
      {chatMessages.messages.map((message: any) => {
        const isBotMessage = !message.user_id;
        return (
          <div>
            <div
              className={`chat-message ${
                isBotMessage ? 'message-bot bot' : 'message-bot'
              }`}
            >
              <SenderAvatar isBot={isBotMessage} />
              <div className="query-response">
                <TextMessage
                  message={message.summary}
                  animate={message.id == animateId ? true : false}
                  onTypingStatusChange={(status: boolean) => {
                    toggleAutoSuggestInput(message, status);
                  }}
                  hasContent={
                    !!(
                      (message.summary && message.summary !== '') || // Check if summary exists and is not empty
                      (message.image && message.image.length > 0) || // Check if images exist
                      (message.video && message.video.length > 0)
                    ) // Check if videos exist
                  }
                />
                {message.image && <ImageList images={message.image} />}
                {message.video && (
                  <VideoList
                    recordId={message.id}
                    videos={message.video}
                    playingVideoId={playingVideoId}
                    onVideoPlay={(videoId: string | null) => {
                      onVideoPlay(videoId);
                    }}
                  />
                )}
              </div>
            </div>
            {message.id !== animateId &&
              isBotMessage &&
              message.summary &&
              message.message && (
                <DetailedMessageIcon
                  isActive={message.showDetail}
                  toggleDetailedAnswer={() => toggleDetailedAnswer(message)}
                />
              )}

            {message.showDetail && (
              <DetailedMessage isBotMessage={isBotMessage} message={message} />
            )}
          </div>
        );
      })}
      {isLoading && <LoadingMessage />}
    </div>
  );
};
const ChatMessages: React.FC<ChatMessagesProps> = ({
  chatMessages,
  toggleDetailedAnswer,
  onVideoPlay,
  toggleAutoSuggestInput,
  animateId,
  playingVideoId,
  isLoading,
  isInitialLoad,
}) => {
  if (isInitialLoad && chatMessages.messages.length <= 0) {
    return <Greeting />;
  }
  return (
    <ScrollToBottom className="scroller" followButtonClassName="scroll-btn">
      <Content
        chatMessages={chatMessages}
        toggleDetailedAnswer={toggleDetailedAnswer}
        toggleAutoSuggestInput={toggleAutoSuggestInput}
        animateId={animateId}
        playingVideoId={playingVideoId}
        onVideoPlay={onVideoPlay}
        isLoading={isLoading}
        isInitialLoad={isInitialLoad}
      />
    </ScrollToBottom>
  );
};

export default ChatMessages;
