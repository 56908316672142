import { ReactComponent as DetailActiveIcon } from '../../detailed-active-icon.svg';
import { ReactComponent as DetailIcon } from '../../detailed-icon.svg';
interface DetailedMessageProps {
  isActive: boolean;
  toggleDetailedAnswer: () => any;
}
const DetailedMessageIcon: React.FC<DetailedMessageProps> = ({
  isActive,
  toggleDetailedAnswer,
}) => {
  return isActive ? (
    <DetailActiveIcon
      className="show-detail-icon pointer"
      onClick={() => toggleDetailedAnswer()}
    />
  ) : (
    <DetailIcon
      className="show-detail-icon pointer"
      onClick={() => toggleDetailedAnswer()}
    />
  );
};
export default DetailedMessageIcon;
