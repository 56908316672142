import React, { useEffect, useState } from 'react';
import {
  Layout,
  Card,
  ConfigProvider,
  Table,
  Row,
  Col,
  Typography,
  Tabs,
  Button,
  Select,
  Flex,
  Divider,
} from 'antd';
import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ArrowLeftOutlined, LockFilled } from '@ant-design/icons';
import {
  peopleAssessmentStatus,
  courseAssessmentStatus,
  userDetails,
  getAssessmentAttempted,
} from '../services/ApiActions/adminAct';
import DoughnutChart from './DoughnutChart';
import { getCourses } from '../services/ApiActions/adminAct';
import Loader from '../components/Layout/Loader';
import ErrorMessageCard from '../components/Error/ErrorMessageCard';
const { Content, Sider } = Layout;
const { Title } = Typography;

const PeoplePage = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [assessmentsData, setAssessmentsData] = useState<any[]>([]);
  const [quizAssessmentsData, setQuizAssessmentsData] = useState<any[]>([]);
  const [assessmentSummary, setAssessmentSummary] = useState<any>([]);
  const [userData, setUserData] = useState<any>(null);
  //const [selectedTab, setSelectedTab] = useState('0');
  const [assessmentCount, setAssessmentCount] = useState<any>(null);
  const [courseOptions, setCourseOptions] = useState<{
    options: any[];
    defaultOption: string;
  }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isInitialCall, setIsInitialCall] = useState(true);
  const { userId, selectedTab } = params;
  const course_id = searchParams.get('course_id');
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading

      try {
        const promises = [
          peopleAssessmentStatus(userId as string, course_id as string),
          getAssessmentAttempted(userId as string),
          courseAssessmentStatus(userId as string, course_id as string),
          userDetails(userId as string),
          getCourses(),
        ];

        const [res, assessmentAttempted, resp_quiz, user, courses]: any =
          await Promise.all(promises);

        setUserData(user);
        setIsInitialCall(false);

        console.log('assessmentAttempted response', assessmentAttempted.data);
        const assessmentAttemptedData = [
          {
            title: 'Attempted',
            value: assessmentAttempted.data.attempted,
            color: '#B5CAE7',
          },
          {
            title: 'Not Attempted',
            value: assessmentAttempted.data.not_attempted,
            color: '#4566A9',
          },
        ];
        setAssessmentSummary(assessmentAttemptedData);
        setAssessmentCount(assessmentAttempted.data);
        console.log('Attempted', assessmentAttempted.data);

        const validData = res.data.find(
          (item: any) => item && item.assessments
        );
        console.log('validata', validData);
        if (validData) {
          // Extract assessments data
          const records: any = [];
          res.data.forEach(
            (item: { id: string; assessments: any[]; name: string }) => {
              item.assessments.forEach((assessment: any) => {
                records.push({
                  key: `${item.id}-${assessment.id}`,
                  course_name: item.name,
                  assessment_name: assessment.name,
                  course_id: item.id,
                  assessment_id: assessment.id,
                  assessment_status: assessment.assessment_status,
                });
              });
            }
          );

          setAssessmentsData(records);
        }

        const quizProgressData = resp_quiz.data.map((module: any) => ({
          key: module.id,
          course: module.course,
          module: module.module,
          courseId: module.course_id,
          moduleId: module.module_id,
          status: module.course_status,
          percentage: module.percentage,
          userId: userId,
        }));
        setQuizAssessmentsData(quizProgressData);

        const options = courses.data.map((course: any) => {
          return { value: course.id, label: course.name };
        });
        options.unshift({ label: 'All', value: '' });
        const defaultSelectedCouse =
          course_id && options.find((option: any) => option.value == course_id);

        setCourseOptions({
          options: options,
          ...(defaultSelectedCouse && {
            defaultOption: defaultSelectedCouse.value,
          }),
        });
      } catch (error) {
        console.error('Error fetching People Page Api', error);
      } finally {
        setIsLoading(false); // End loading after all operations
      }
    };

    fetchData();
  }, []);
  //
  const fetchProgressData = async () => {
    setIsLoadingData(true); // Start loading
    try {
      if (selectedTab === 'course-progress') {
        const resp_quiz: any = await courseAssessmentStatus(
          userId as string,
          course_id as string
        );
        const quizProgressData = resp_quiz.data.map((module: any) => ({
          key: module.id,
          course: module.course,
          module: module.module,
          courseId: module.course_id,
          moduleId: module.module_id,
          status: module.course_status,
          percentage: module.percentage,
          userId: userId,
        }));
        setQuizAssessmentsData(quizProgressData);
      } else {
        const res: any = await peopleAssessmentStatus(
          userId as string,
          course_id as string
        );
        const validData = res.data.find(
          (item: any) => item && item.assessments
        );
        if (validData) {
          // Extract assessments data
          const records: any = [];
          res.data.forEach(
            (item: { id: string; assessments: any[]; name: string }) => {
              item.assessments.forEach((assessment: any) => {
                records.push({
                  key: `${item.id}-${assessment.id}`,
                  course_name: item.name,
                  assessment_name: assessment.name,
                  course_id: item.id,
                  assessment_id: assessment.id,
                  assessment_status: assessment.assessment_status,
                });
              });
            }
          );

          setAssessmentsData(records);
        }
      }
    } catch (error) {
      console.error('Error fetching People Page Api', error);
    } finally {
      setIsLoadingData(false); // End loading after all operations
    }
  };
  useEffect(() => {
    !isInitialCall && fetchProgressData();
  }, [course_id, selectedTab]);
  //
  const handleOnTabClick = (selectedTab: string) => {
    //setSelectedTab(tabIndex);

    navigate(`/people/${userId}/${selectedTab}`);
    const newOptions: any = { ...courseOptions, defaultOption: '' };
    setCourseOptions(newOptions);
  };
  const handleCourseChange = (course: string) => {
    const newOptions: any = { ...courseOptions, defaultOption: course };
    setCourseOptions(newOptions);
    navigate(`/people/${userId}/${selectedTab}?course_id=${course}`);
  };
  const columns = [
    {
      title: 'COURSES',
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: 'ASSESSMENT ',
      dataIndex: 'assessment_name',
      key: 'assessment_name',
    },
    {
      title: 'STATUS',
      dataIndex: 'assessment_status',
      key: 'assessment_status',
      render: (text: any, record: any) => {
        return (
          <Link
            to={`/admin-assessment?assessment_id=${record.assessment_id}&course_id=${record.course_id}&user_id=${userId}&return_url=${currentUrl}`}
            className={
              text?.toLowerCase() === 'review pending'
                ? 'text-red'
                : 'text-default'
            }
          >
            {text}
          </Link>
        );
      },
    },
  ];

  const columnsCourseProgress = [
    {
      title: 'COURSES',
      dataIndex: 'course',
      key: 'course',
    },
    {
      title: 'MODULE',
      dataIndex: 'module',
      key: 'module',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <Link
          to={`/admin-quiz-review?course_id=${record.courseId}&module_id=${record.moduleId}&user_id=${record.userId}&return_url=${currentUrl}`}
          className={
            text?.toLowerCase() === 'not started'
              ? 'text-red'
              : text?.toLowerCase() === 'completed'
              ? 'text-green'
              : 'text-default'
          }
        >
          {text?.toLowerCase() === 'in progress'
            ? `${(100 - record.percentage).toFixed(0)}% to complete`
            : text}
        </Link>
      ),
    },
  ];
  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <Layout>
      <Content>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
          }}
        >
          <ArrowLeftOutlined
            style={{ fontSize: '23px', marginRight: '10px' }}
            onClick={() => navigate('/admin')}
          />
          <Typography.Title level={3} style={{ margin: 0 }}>
            People
          </Typography.Title>
        </div>
        <Layout>
          <Sider style={{ backgroundColor: 'transparent' }} width={310}>
            <div
              style={{
                background: '#fff',
                borderRadius: '10px',
                border: 'solid 1px #ccc',
              }}
            >
              <div style={{ padding: '15px 15px 0' }}>
                <Title level={4} style={{ marginTop: 0, marginBottom: '10px' }}>
                  {userData?.username}
                </Title>
                <p style={{ margin: 0, fontWeight: 500 }}>
                  {userData?.department}
                </p>
                <p style={{ margin: 0 }}>{userData?.designation}</p>
                <p style={{ margin: 0 }}>{userData?.email}</p>
              </div>

              <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
              <div style={{ padding: '0 15px' }}>
                <div>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Assessments attempted
                  </Title>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    Total Assessments
                  </Title>
                  <Title level={2} style={{ marginTop: 0 }}>
                    {assessmentCount?.total ?? 'Loading...'}
                  </Title>
                </div>

                {assessmentSummary && (
                  <div style={{ height: '150px', width: '250px' }}>
                    <DoughnutChart data={assessmentSummary} />
                  </div>
                )}

                <div style={{ flexGrow: '1' }}>
                  <ul
                    style={{
                      fontSize: '16px',
                      listStyleType: 'none',
                      marginLeft: 0,
                      paddingLeft: '1.8em',
                      textIndent: '-1.8em',
                    }}
                  >
                    <li style={{ marginBottom: '10px' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '18px',
                          height: '18px',
                          background: '#B5CAE7',
                          marginRight: '10px',
                          borderRadius: '50%',
                        }}
                      ></span>
                      Attempted:{' '}
                      <span style={{ fontWeight: 600 }}>
                        {assessmentCount?.attempted}
                      </span>
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '18px',
                          height: '18px',
                          background: '#4566A9',
                          marginRight: '10px',
                          borderRadius: '50%',
                        }}
                      ></span>
                      Not attempted:{' '}
                      <span style={{ fontWeight: 600 }}>
                        {assessmentCount?.not_attempted}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Sider>
          <Layout>
            <Content
              className="site-layout-background"
              style={{
                padding: '0 0 0 10px',
                margin: 0,
                minHeight: 280,
              }}
            >
              <Card style={{ height: '100%' }}>
                <Flex>
                  <div style={{ display: 'flex', flexGrow: '1' }}>
                    <div className="admin-tab" style={{ display: 'flex' }}>
                      <span
                        onClick={() => handleOnTabClick('assessment')}
                        className={
                          selectedTab == 'assessment'
                            ? 'active pointer-css'
                            : 'pointer-css'
                        }
                      >
                        Assessments
                      </span>
                      |
                      <span
                        onClick={() => handleOnTabClick('course-progress')}
                        className={
                          selectedTab == 'course-progress'
                            ? 'active pointer-css'
                            : 'pointer-css'
                        }
                      >
                        Course Progress
                      </span>
                    </div>
                  </div>
                  {courseOptions && (
                    <Select
                      placeholder="Select Course"
                      value={courseOptions.defaultOption}
                      onChange={handleCourseChange}
                      options={courseOptions.options}
                      style={{ minWidth: '200px' }}
                    />
                  )}
                </Flex>
                {/* <Tabs defaultActiveKey="1">

                  <TabPane className='tabs-header' tab="Assessments" key="1">

                    <Table className='users-list' dataSource={assessmentsData} columns={columns} pagination={false} />

                  </TabPane>

                  <TabPane className='tabs-header' tab="Course Progress" key="2">
                    <Table className='users-list' dataSource={quizAssessmentsData} columns={columnsCourseProgress} pagination={false} />
                  </TabPane>
                </Tabs> */}
                {selectedTab === 'assessment' &&
                assessmentsData.length === 0 ? (
                  <ErrorMessageCard message="No assessment to review." />
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          cellFontSize: 16,
                        },
                      },
                    }}
                  >
                    {selectedTab === 'assessment' ? (
                      <Table
                        className="custom-table assessment-list"
                        key="table-0"
                        loading={isLoadingData}
                        dataSource={assessmentsData}
                        columns={columns}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        key="table-1"
                        loading={isLoadingData}
                        className="custom-table progress-list"
                        dataSource={quizAssessmentsData}
                        columns={columnsCourseProgress}
                        pagination={false}
                      />
                    )}
                  </ConfigProvider>
                )}
              </Card>
            </Content>
          </Layout>
        </Layout>
      </Content>
    </Layout>
  );
};

export default PeoplePage;
