import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import {
  Select,
  Layout,
  Menu,
  Card,
  Typography,
  Input,
  Button,
  message,
  Flex,
  List,
  Breadcrumb as AntBreadcrumb,
  Form,
  Radio,
  Modal,
  Space,
} from 'antd';
import {
  getAssessmentByCourseId,
  getAssessmentResultByAdmin,
  peopleAssessmentStatus,
  //getAllModulesByCourseId,
  userDetails,
  getCourses,
} from '../services/ApiActions/adminAct';
import { getAllModulesByCourseId } from '../services/ApiActions/lmsAct';
import {
  ArrowLeftOutlined,
  LockFilled,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import Loader from '../components/Layout/Loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getUserName } from '../helper/UtilityMethods';
import ErrorMessageCard from '../components/Error/ErrorMessageCard';
const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

interface Option {
  id: number;
  option: string;
}

interface AssessmentQuestion {
  id: number;
  question: string;
  submitted_answer: string;
  is_multiple_choice_question: boolean;
  is_correct: boolean | null;
  options: Option[];
  correct_answer: string;
}

interface AssessmentResponse {
  status: string;
  data: {
    name: string;
    is_attempted: boolean;
    assessment_questions: AssessmentQuestion[];
    assessment_status: string;
    assessment_result: string;
    assessment_reviewed_by: string;
  };
}

const AdminAssessmentPage = () => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isInitialing, setIsInitialing] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const [reviewerName, setReviewerName] = useState('');
  const [coursesOption, setCoursesOption] = useState<any>();
  const [courseAssessments, setCourseAssessments] = useState<any | undefined>(
    undefined
  );
  const [defaultCourse, setDefaultCourse] = useState<string | undefined>();
  const [courseName, setCourseName] = useState('');
  const [assessmentName, setAssessmentName] = useState('');
  const [userData, setUserData] = useState<any>(null);
  const [assessmentDetails, setAssessmentDetails] = useState<any>(null);
  const user_id = searchParams.get('user_id');
  const course_id = searchParams.get('course_id');
  const assessment_id = searchParams.get('assessment_id');
  const return_url = searchParams.get('return_url');
  const [isProcessing, setIsProcessing] = useState(false);
  const [passFailStatus, setPassFailStatus] = useState<string | null>(null);
  const [isDeclared, setIsDeclared] = useState(false);
  const componentRef: any = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    const initialData = async () => {
      if (user_id) {
        try {
          setIsInitialing(true);
          const response: any = await getCourses();
          const user: any = await userDetails(user_id as string);
          setUserData(user);
          if (response.status === 'success') {
            const options: any = response.data.map((c: any) => {
              return { value: c.id, label: c.name, assessments: [] };
            });
            setCoursesOption(options);
            //course_id ||
            const selectedCourse = options.find(
              (option: any) => option.value == course_id
            );
            const defaultCourse = selectedCourse
              ? selectedCourse.value
              : options[0].value;
            options.length > 0 && setDefaultCourse(defaultCourse);
            options.length > 0 && setCourseName(options[0].label);
          }
        } catch (error) {
          console.error('Error fetching courses:', error);
        } finally {
          setIsInitialing(false);
        }
      }
    };

    initialData();
  }, []);
  // useEffect(() => {
  //   const fetchCourses = async () => {
  //     if(user_id){
  //       try {
  //         const response: any = await peopleAssessmentStatus(user_id, course_id);
  //         if (response.status === 'success') {
  //           const options: any =  response.data.map((c:any) => {
  //             return { value: c.id, label: c.name, assessments:  c.assessments}
  //           })
  //           setCoursesOption(options);
  //           (options.length > 0) && setDefaultCourse(options[0].value);
  //           (options.length > 0) && setCourseName(options[0].label);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching courses:', error);
  //       } finally {
  //         //setIsLoading(false);
  //       }
  //     }

  //   };

  //   fetchCourses();
  // }, []);
  // useEffect(() => {
  //   const selectedOption:any = coursesOption.find((option: any) => option.value == defaultCourse);
  //   selectedOption && setAssessmentName(selectedOption.label);
  //   selectedOption && setCourseAssessments(selectedOption.assessments)
  // }, [defaultCourse])

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response: any =
          course_id && user_id && (await getAllModulesByCourseId(course_id));
        if (response.status === 'success') {
          setCourseAssessments(response.data.assessments);
          if (!assessment_id) {
            setAssessmentName(response.data.assessments[0].name);
            navigate(
              `/admin-assessment?assessment_id=${response.data.assessments[0].id}&course_id=${course_id}&user_id=${user_id}`
            );
            return;
          }
          const selectedAssessment = response.data.assessments.find(
            (assessment: any) => assessment.id == assessment_id
          );
          selectedAssessment && setAssessmentName(selectedAssessment.name);
        } else {
          console.error(
            'Failed to fetch courses:',
            response.message || 'Unknown error'
          );
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, [course_id]);
  useEffect(() => {
    const fetchAssessment = async () => {
      if (!user_id || !assessment_id) return;

      setLoading(true);
      setError(null);

      try {
        //do not relay on this api for breadcrum it does not retun for not started assessment
        const response: any = await getAssessmentByCourseId(
          assessment_id,
          course_id as string,
          user_id
        );
        const failPass =
          response.data.assessment_result &&
          response.data.assessment_result.toLowerCase();
        setAssessmentDetails(response.data);
        setPassFailStatus(failPass);
        setReviewerName(response.data.assessment_reviewed_by);
        setIsProcessing(true);
        setIsDeclared(
          failPass === 'fail' || failPass === 'pass' ? true : false
        );
        //setAssessmentName(response.data.name)

        console.log('response for assessment', response.data);
      } catch (error) {
        console.error('Error fetching assessment:', error);
        setError('Failed to fetch assessment');
      } finally {
        setLoading(false);
      }
    };

    fetchAssessment();
  }, [assessment_id, course_id, user_id]);

  // TODO: Need to test
  useEffect(() => {
    let localAssesmentData: any = localStorage.getItem('assesmentResult');

    localAssesmentData = JSON.parse(localAssesmentData) || {};

    // if (Object.keys(localAssesmentData).length > 0) {
    //   setPassFailStatus(localAssesmentData.data.assessment_result);
    //   if (localAssesmentData.data.assessment_result?.toLocaleLowerCase() === "pass" || localAssesmentData.data.assessment_result?.toLocaleLowerCase() === "fail") {
    //     setIsDeclared(true);
    //   }
    // }
  }, []);

  useEffect(() => {
    if (error) {
      message.error(error);
      setError(''); // Clear error after showing the message
    }
  }, [error]);

  const handleDeclare = async (assessment_result: 'Pass' | 'Fail') => {
    setLoading(true);
    setError(null);
    setModalVisible(true);

    setModalContent(
      <>
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          Declared {assessment_result}
        </Typography.Title>

        <div
          style={{
            borderTop: 'solid 1px #CBD0DD',
            borderBottom: 'solid 1px #F0F0F0',
            padding: '24px 0',
          }}
        >
          {userData && userData.username} has been declared {assessment_result}{' '}
          for assessment <b>{assessmentName}</b>
        </div>
      </>
    );
    setIsDeclared(true);
    setReviewerName(getUserName());
    const data = {
      assessment_result: assessment_result,
      assessment_id: assessment_id,
      user_id: user_id,
      course_id: course_id,
    };
    try {
      let res: any = await getAssessmentResultByAdmin(data);
      setPassFailStatus(res.data.assessment_result.toLowerCase());
      localStorage.setItem('assesmentResult', JSON.stringify(res));
      console.log('response for result', res);
    } catch (error) {
      console.error('Error declaring result:', error);
      setError('Failed to declare result');
    } finally {
      setLoading(false);
    }
  };
  const handleCourseChange = (course: string) => {
    //const selectedOption:any =  coursesOption && coursesOption.find((option: any) => option.id == course);
    //selectedOption && setCourseName(selectedOption.label);
    //setDefaultCourse(course);
    navigate(`/admin-assessment?course_id=${course}&user_id=${user_id}`);
  };
  const handleAssessmentClick = (assessment: any) => {
    navigate(
      `/admin-assessment?assessment_id=${assessment.id}&course_id=${course_id}&user_id=${user_id}`
    );
    //console.log(assessment.id)
  };

  const downloadPdf = () => {
    console.log(assessmentDetails);
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const radioButtonSize = 1; // Radius of the radio button
    let yOffset = margin;

    const data = assessmentDetails.assessment_questions;
    pdf.setFontSize(12);

    // Wrap the question text
    const resultLines = pdf.splitTextToSize(
      `Result: ${assessmentDetails.assessment_result}`,
      maxLineWidth
    );
    yOffset += 5;
    pdf.text(resultLines, margin, yOffset);
    yOffset += resultLines.length * 7; // Adjust spacing based on number of lines

    data.forEach((item: any, i: number) => {
      pdf.setFontSize(11);
      const questionLines = pdf.splitTextToSize(
        `${i + 1}. ${item.question.trim()}`,
        maxLineWidth
      );
      pdf.text(questionLines, margin, yOffset);
      yOffset += questionLines.length * 6; // Adjust spacing based on number of lines

      // Check if there are options (for multiple choice questions)
      let ansLabel = ``;
      let answer = item.submitted_answer;
      const answerMargin = margin + 5;
      if (item.is_multiple_choice_question && item.options.length > 0) {
        pdf.setFontSize(10);

        item.options.forEach((option: any, index: number) => {
          // Draw the radio button circle
          ansLabel = item.is_correct ? 'Correct Answer: ' : 'Correct Answer: ';
          const radioButtonX = margin + 5;
          const radioButtonY = yOffset - 1; // Adjust for alignment
          const isSelected = item.submitted_answer == index + 1 ? true : false;
          if (isSelected) {
            answer = option.option.trim();
          }
          pdf.setFillColor(isSelected ? '#000' : '#fff'); // Black for selected, white for unselected
          pdf.circle(radioButtonX, radioButtonY, radioButtonSize, 'F'); // Draw filled circle
          pdf.setFillColor(0, 0, 0); // Set color for the border
          pdf.circle(radioButtonX, radioButtonY, radioButtonSize, 'D'); // Draw border

          const optionText = `${option.option.trim()}`;
          const optionLines = pdf.splitTextToSize(
            optionText,
            maxLineWidth - radioButtonSize - 10
          ); // Adjust width for radio button
          pdf.text(optionLines, radioButtonX + radioButtonSize + 2, yOffset);
          yOffset += optionLines.length * 6; // Adjust spacing based on number of lines

          // Handle page overflow within options loop
          if (yOffset > pageHeight - margin) {
            pdf.addPage();
            yOffset = margin;
          }
        });
      }

      if (item.is_multiple_choice_question) {
        const correctWrongText = pdf.splitTextToSize(
          `${item.is_correct ? 'Correct' : 'Wrong'}`,
          maxLineWidth
        );
        pdf.text(correctWrongText, answerMargin, yOffset);
        yOffset += correctWrongText.length * 7; // Adjust spacing based on number of lines
      }

      // Wrap the correct answer text
      if (
        (item.is_multiple_choice_question && !item.is_correct) ||
        !item.is_multiple_choice_question
      ) {
        const answerLines = pdf.splitTextToSize(
          `${ansLabel} ${answer.trim()}`,
          maxLineWidth
        );
        pdf.text(answerLines, answerMargin, yOffset);
        yOffset += answerLines.length * 7; // Adjust spacing based on number of lines
      }

      // Handle page overflow
      if (yOffset > pageHeight - margin) {
        pdf.addPage();
        yOffset = margin;
      }
    });

    pdf.save(`${assessmentDetails.name}.pdf`);
  };

  // const downloadPdf = () => {
  //   const input = componentRef.current;

  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   const pageHeight = pdf.internal.pageSize.getHeight();
  //   const margin = 10;
  //   let yOffset = margin;

  //   // Loop through child elements and add them as text
  //   Array.from(input.childNodes).forEach((node:) => {
  //     if (node.nodeType === 1) {
  //       // Element node
  //       const tagName = node.tagName.toLowerCase();

  //       if (tagName === 'h1') {
  //         pdf.setFontSize(22);
  //         pdf.text(node.innerText, margin, yOffset);
  //         yOffset += 10; // Adjust spacing after heading
  //       } else if (tagName === 'p') {
  //         pdf.setFontSize(12);
  //         const lines = pdf.splitTextToSize(node.innerText, 180); // Wrap text within page width
  //         pdf.text(lines, margin, yOffset);
  //         yOffset += lines.length * 5; // Adjust spacing based on the number of lines
  //       }

  //       // Handle page overflow
  //       if (yOffset > pageHeight - margin) {
  //         pdf.addPage();
  //         yOffset = margin;
  //       }
  //     }
  //   });

  //   pdf.save('download.pdf');
  // };
  // const downloadPdf = () => {
  //   const input = componentRef.current;

  //   // Create a deep clone of the component to avoid affecting the original
  //   const clonedComponent = input.cloneNode(true);

  //   // Set desired width for PDF on the cloned component
  //   clonedComponent.style.width = '300px';
  //   clonedComponent.style.position = 'absolute';
  //   clonedComponent.style.left = '-9999px'; // Hide the cloned component off-screen
  //   document.body.appendChild(clonedComponent);
  //   html2canvas(clonedComponent, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdfWidth = canvas.width;
  //     const pdfHeight = canvas.height;

  //     const pdf = new jsPDF({
  //       orientation: 'portrait',
  //       unit: 'px',
  //       format: [pdfWidth, pdfHeight],
  //     });

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('download.pdf');
  //   });
  // };
  if (isInitialing) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Flex align="center">
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowLeftOutlined
              style={{ fontSize: '23px', marginRight: '10px' }}
              onClick={() =>
                navigate(
                  return_url ? return_url : `/people/${user_id}/assessment`
                )
              }
            />
            <Typography.Title level={3} style={{ margin: 0 }}>
              {assessmentName}
            </Typography.Title>
          </div>
          <AntBreadcrumb style={{ margin: '0 0 16px' }}>
            <AntBreadcrumb.Item>LMS</AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{courseName}</AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{assessmentName}</AntBreadcrumb.Item>
          </AntBreadcrumb>
        </div>
        {isDeclared && (
          <div>
            <div className="tab-button" onClick={() => downloadPdf()}>
              <DownloadOutlined /> Download Assessment
            </div>
          </div>
        )}
      </Flex>

      <Layout>
        <Sider width={300} style={{ background: 'none' }}>
          <Card style={{ marginBottom: '10px' }}>
            <Title level={4} style={{ marginTop: 0, marginBottom: '10px' }}>
              {userData?.username}
            </Title>
            <p style={{ margin: 0, fontWeight: 500 }}>{userData?.department}</p>
            <p style={{ margin: 0 }}>{userData?.designation}</p>
            <p style={{ margin: 0 }}>{userData?.email}</p>
          </Card>
          <Card>
            <Title level={3} style={{ marginTop: 0 }}>
              Review assessment for Course:
            </Title>
            {defaultCourse && (
              <Select
                defaultValue={defaultCourse}
                style={{ width: '100%' }}
                onChange={handleCourseChange}
                options={coursesOption}
              />
            )}
            <ul className="course-list">
              {
                //type={(defaultCourse == courseAssessment.id)? "primary" : undefined}
                courseAssessments &&
                  courseAssessments.map((courseAssessment: any) => (
                    <li
                      className={
                        courseAssessment.id == assessment_id ? 'active' : ''
                      }
                      onClick={() => handleAssessmentClick(courseAssessment)}
                    >
                      {courseAssessment.name}
                    </li>
                  ))
              }
            </ul>
          </Card>
        </Sider>
        <Content
          className="site-layout-background"
          style={{ padding: '0 0 0 10px', margin: 0 }}
        >
          <Card style={{ minHeight: '100%' }} ref={componentRef}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Review Assessment
              </Title>

              {passFailStatus &&
                (passFailStatus === 'fail' || passFailStatus === 'pass') && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '18px',
                      fontWeight: 500,
                      gap: '9px',
                    }}
                  >
                    <Text
                      style={{
                        borderRadius: '4px',
                        padding: '5px 25px',
                        color: '#fff',
                        backgroundColor:
                          passFailStatus === 'pass' ? '#52C41A' : '#FF4D4F',
                      }}
                      type="success"
                    >
                      {passFailStatus === 'pass' ? 'Pass' : 'Fail'}
                    </Text>
                    <Text italic type="secondary" style={{ color: '#1D322D' }}>
                      Reviewed by: {reviewerName}
                    </Text>
                  </div>
                )}
            </div>
            {loading ? (
              <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
            ) : !assessmentDetails?.is_attempted ? (
              <ErrorMessageCard message="To review this assessment, the user must first attempt it." />
            ) : (
              <>
                <Form form={form} className="quiz-question admin">
                  {assessmentDetails?.assessment_questions.map(
                    (question: AssessmentQuestion, index: number) => {
                      const {
                        is_correct,
                        correct_answer,
                        options,
                        submitted_answer,
                      } = question;
                      const answerIndex = correct_answer
                        ? parseInt(correct_answer) - 1
                        : -1;
                      return (
                        <div key={question.id}>
                          <p style={{ marginTop: 0 }}>
                            {index + 1}. {question.question}
                          </p>
                          <Form.Item
                            style={{ marginBottom: '20px', marginLeft: '15px' }}
                            name={`question-${question.id}`}
                            rules={[
                              { required: true, message: 'Select your answer' },
                            ]}
                          >
                            {question.is_multiple_choice_question ? (
                              <Radio.Group
                                defaultValue={Number(submitted_answer)}
                                disabled={assessmentDetails.is_attempted}
                              >
                                {options.map((option, idx) => {
                                  const isSelected =
                                    Number(submitted_answer) === idx + 1;
                                  return (
                                    <div
                                      style={{ marginBottom: '10px' }}
                                      key={idx}
                                    >
                                      <Radio
                                        value={idx + 1}
                                        checked={isSelected}
                                      >
                                        {option.option}
                                      </Radio>
                                      <br />
                                    </div>
                                  );
                                })}
                              </Radio.Group>
                            ) : (
                              <TextArea
                                className="subjective-answer"
                                autoSize={assessmentDetails.is_attempted}
                                style={{
                                  background: '#F5F5F5',
                                  borderColor: '#D9D9D9',
                                }}
                                readOnly={assessmentDetails.is_attempted}
                                placeholder="Your Answer"
                                defaultValue={submitted_answer}
                              />
                            )}
                          </Form.Item>
                          {typeof is_correct !== 'undefined' &&
                            question.is_multiple_choice_question && (
                              <div
                                style={{
                                  marginBottom: '20px',
                                  marginLeft: '15px',
                                }}
                              >
                                {is_correct ? (
                                  <Text
                                    className="option-response"
                                    type="success"
                                  >
                                    <CheckOutlined /> Correct!
                                  </Text>
                                ) : (
                                  <Text
                                    className="option-response"
                                    type="danger"
                                  >
                                    <CloseOutlined /> Wrong!
                                  </Text>
                                )}
                              </div>
                            )}
                          {typeof is_correct !== 'undefined' &&
                            !is_correct &&
                            !isProcessing &&
                            answerIndex >= 0 && (
                              <div
                                style={{
                                  marginBottom: '20px',
                                  marginLeft: '15px',
                                }}
                              >
                                *Correct answer is{' '}
                                <span>"{options[answerIndex].option}"</span>.
                              </div>
                            )}
                        </div>
                      );
                    }
                  )}
                </Form>
                {!isDeclared && assessmentDetails?.is_attempted && (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Button
                      danger
                      onClick={() => handleDeclare('Fail')}
                      style={{ color: '#000' }}
                    >
                      Declare Fail
                    </Button>{' '}
                    &nbsp;
                    <Button
                      type="primary"
                      style={{ background: '#389E0D', color: '#fff' }}
                      onClick={() => handleDeclare('Pass')}
                    >
                      Declare Pass
                    </Button>
                  </div>
                )}
              </>
            )}
          </Card>
        </Content>
      </Layout>
      <Modal
        visible={modalVisible}
        closable={false}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        {modalContent}
      </Modal>
    </Layout>
  );
};

export default AdminAssessmentPage;
