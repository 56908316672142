import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { getAdminNotifications } from '../../services/ApiActions/notificationAct';

// Define the shape of a notification object
interface Notification {
  id: number;
  message: string;
  read: boolean;
  // Add other properties if needed
}

interface AdminNotificationContextProps {
  unreadCount: number;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>;
  fetchNotifications: () => Promise<void>;
  recentNotifications: Notification[] | null;
  setRecentNotifications: React.Dispatch<
    React.SetStateAction<Notification[] | null>
  >;
  markNotificationRead: (notification?: Notification) => void;
}

// Create the context with type AdminNotificationContextProps or undefined
const AdminNotificationContext = createContext<
  AdminNotificationContextProps | undefined
>(undefined);

// Hook to access the context
export const AdminNotification = () => {
  const context = useContext(AdminNotificationContext);
  if (!context) {
    throw new Error(
      'adminNotification must be used within an AdminNotificationProvider'
    );
  }
  return context;
};

// Provider component for the AdminNotificationContext
export const AdminNotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [recentNotifications, setRecentNotifications] = useState<
    Notification[] | null
  >(null);

  // Fetch notifications function

  const fetchNotifications = async () => {
    try {
      const response: any = await getAdminNotifications(false);
      const recent5Notifications: any = await getAdminNotifications(
        undefined,
        1,
        3
      );

      if (response.status === 'success') {
        const unread = response.data.results.length;
        setUnreadCount(unread);
        setRecentNotifications(recent5Notifications.data.results);
      } else {
        console.error(
          'Failed to fetch notifications: ',
          response.message || 'Unknown Error'
        );
      }
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Function to mark a notification as read
  const markNotificationRead = (notification?: Notification) => {
    notification &&
      setRecentNotifications(
        (prevNotifications) =>
          prevNotifications &&
          prevNotifications.map((recentNotification) => {
            // If no specific notification is passed, mark all as read
            const shouldMarkAsRead =
              !notification || recentNotification.id === notification.id;

            return shouldMarkAsRead
              ? { ...recentNotification, read: true }
              : recentNotification;
          })
      );
  };

  return (
    <AdminNotificationContext.Provider
      value={{
        unreadCount,
        setUnreadCount,
        fetchNotifications,
        recentNotifications,
        setRecentNotifications,
        markNotificationRead,
      }}
    >
      {children}
    </AdminNotificationContext.Provider>
  );
};
