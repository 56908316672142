import { ClassNames } from "@emotion/react";

interface LoaderProps {
  style?: React.CSSProperties;
  showBox?: boolean;
}
const Loader: React.FC<LoaderProps> = ({style, showBox}) => {
  return (
    <div
      className='loader-container'
      style={style}
    >
      <div className={(showBox)? 'loader-box' : ''}>
        <div className="loader">
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
