import React from 'react';
import { Card, Typography } from 'antd';
import RecordItem from './RecordItem';
import DateTitle from './DateTitle';
const { Title } = Typography;

interface RecordsListProps {
  records: any[];
  selectedTab: string;
  isNewsletter?: boolean;
  playingVideoId?: string | null;
  priority?: 'oldest' | 'newest' | 'mostrelevant';
  onReadMoreClick: (title: string, url: string) => void;
  onVideoPlay?: (isPlaying: string | null) => void;
  tabs?: any;
}

const RecordsList: React.FC<RecordsListProps> = ({
  tabs,
  records,
  selectedTab,
  priority,
  onReadMoreClick,
  isNewsletter,
  playingVideoId,
  onVideoPlay,
}) => {
  if (priority && selectedTab === 'video') {
    // show no records based on priority filter
    const vidoeWithPriority = records.find((record: any) => {
      return record.records.find(
        (item: any) => item.video_link && item.priority === priority
      );
    });
    if (!vidoeWithPriority) {
      return <Card style={{ marginTop: '20px' }}>No items found</Card>;
    }
  }
  if (
    tabs.length > 0 &&
    tabs.find((tab: any) => tab.key === selectedTab).count <= 0
  ) {
    return <Card style={{ marginTop: '20px' }}>No items found</Card>;
  }

  return (
    <>
      {records.map((record) => (
        <div
          key={record.date}
          style={{
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <DateTitle date={record.date} />
          {record.records.map((item: any) => (
            <RecordItem
              playingVideoId={playingVideoId || null}
              isNewsletter={isNewsletter}
              key={item.id}
              item={item}
              selectedTab={selectedTab}
              priority={priority}
              onReadMoreClick={onReadMoreClick}
              onVideoPlay={(isPlaying: string | null) =>
                onVideoPlay && onVideoPlay(isPlaying)
              }
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default RecordsList;
