import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
interface VideoList {
  videos?: any;
  recordId: string;
  playingVideoId: string | null;
  onVideoPlay: (playingVideoId: string | null) => any;
}
const VideoList: React.FC<VideoList> = ({
  videos,
  playingVideoId,
  recordId,
  onVideoPlay,
}) => {
  return videos.map((video: any) => {
    const { url, id } = video;
    const uniqueVideoId = `${recordId}-${id}`;
    return (
      <div className="video-list">
        <VideoPlayer
          id={uniqueVideoId}
          videoURL={url}
          playingVideoId={playingVideoId}
          onVideoPlay={(videoId: string | null) => onVideoPlay(videoId)}
        />
      </div>
    );
  });
};

export default VideoList;
