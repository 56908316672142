import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Layout,
  DatePicker,
  MenuProps,
  Pagination,
  Select,
} from 'antd';
import {
  EnvelopeOpenIcon,
  BoltIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import {
  EnvelopeOpenIcon as EnvelopeOpenActiveIcon,
  BoltIcon as BoltActiveIcon,
  PlayIcon as PlayActiveIcon,
} from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { getNewsletters } from '../services/ApiActions/newsletterAct';
import BreadcrumbContext from '../components/Context';
import NewsletterLayout from '../components/Layout/NewsletterLayout';
import DateDropdown from '../components/Header/DateDropdown';
import { defaultPageSize, formatData } from '../helper/UtilityMethods';

const { Title } = Typography;
const { Option } = Select;
// const { RangePicker } = DatePicker;

type MenuItem = Required<MenuProps>['items'][number];

const NewsletterPage = () => {
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [selectedDateOption, setSelectedDateOption] = useState('all');
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const iconWidth = 17;
  const [tabsWithCount, setTabsWithCount] = useState([
    {
      label: 'Newsletter',
      key: 'news_letter',
      count: 0,
      icon: <EnvelopeOpenIcon width={iconWidth} />,
      icon_active: <EnvelopeOpenActiveIcon width={iconWidth} />,
    },
    {
      label: 'Summary',
      key: 'news_letter_summary',
      count: 0,
      icon: <BoltIcon width={iconWidth} />,
      icon_active: <BoltActiveIcon width={iconWidth} />,
    },
    {
      label: 'Video',
      key: 'video',
      count: 0,
      icon: <PlayIcon width={iconWidth} />,
      icon_active: <PlayActiveIcon width={iconWidth} />,
    },
  ]);
  const defaultDate =
    searchParams.get('start-date') && searchParams.get('end-date')
      ? [
          dayjs(searchParams.get('start-date')),
          dayjs(searchParams.get('end-date')),
        ]
      : [];
  const [defaultDateRange, setDefaultDateRange] = useState<any>(defaultDate);
  const [selectedTab, setSelectedTab] = useState('news_letter');
  const [records, setRecords] = useState<any[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const dateFormat = 'DD-MM-YYYY';
  const rangePresets: any = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    {
      label: 'Yesterday',
      value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
    },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    {
      label: 'This Month',
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
      label: 'Last Month',
      value: [
        dayjs().add(-1, 'M').startOf('month'),
        dayjs().add(-1, 'M').endOf('month'),
      ],
    },
  ];

  const onRangeChange = (e: any) => {
    navigate({
      pathname: location.pathname,
      search: e
        ? `?start-date=${e[0].format('YYYY-MM-DD')}&end-date=${e[1].format(
            'YYYY-MM-DD'
          )}`
        : '',
    });
  };

  const onTabClick = (tabId: string) => {
    const recordList = formatData(records, tabId);
    setSelectedTab(tabId);
    setFilteredRecords(recordList);
  };

  const onAllClick = () => {
    setSelectedDateOption('all');
    navigate({
      pathname: location.pathname,
      search: ``,
    });
  };
  const handleOnOptionChange = (value: string) => {
    setSelectedDateOption(value);
    setCurrent(1);
    //setPageSize(defaultPageSize);
  };

  useEffect(() => {
    setIsLoading(true);
    const startDate: any = searchParams.get('start-date') || null || undefined;
    const endDate: any = searchParams.get('end-date') || null || undefined;

    getNewsletters(startDate, endDate, current, pageSize)
      .then((response: any) => {
        const recordList = formatData(response.data, selectedTab);
        console.log('total', recordList);
        setFilteredRecords(recordList);
        setRecords(response.data);
        setTotal(response.pagination.total);

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
    console.log('getnewsletters', getNewsletters);
  }, [searchParams, current, pageSize]);

  const handlePageChange = (current: number) => {
    setCurrent(current);
  };

  const handleShowSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrent(current);
  };

  return (
    <>
      <NewsletterLayout
        tabs={tabsWithCount}
        title='Newsletter'
        isNewsletter={true}
        includeDateInHeading={true}
        records={filteredRecords}
        menuItems={menuItems}
        isLoading={isLoading}
        defaultDateRange={defaultDateRange}
        onRangeChange={onRangeChange}
        onAllClick={onAllClick}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
        showDateRange={true}
        pagination={
          total > 0 && (
            <Pagination
              pageSize={pageSize}
              onChange={handlePageChange}
              onShowSizeChange={handleShowSizeChange}
              showSizeChanger={true}
              current={current}
              total={total}
              className='fixed-pagination'
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          )
        }
        selectedDateOption={selectedDateOption}
        dateFilterComponent={
          <DateDropdown
            selectedOption={selectedDateOption}
            onOptionChange={handleOnOptionChange}
          />
        }
      />
    </>
  );
};

export default NewsletterPage;
