import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  Image,
  Typography,
  Layout,
  Collapse,
  CollapseProps,
  MenuProps,
  Menu,
  ConfigProvider,
  Breadcrumb as AntBreadcrumb,
  Flex,
} from 'antd';
import { Link, useParams } from 'react-router-dom';

import Loader from '../components/Layout/Loader';
import BreadcrumbContext from '../components/Context';
import {
  getKnowledgeBaseCategories,
  getKnowledgeBaseDetails,
} from '../services/ApiActions/knowledgeBaseAct';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import PageHeading from '../components/Layout/PageHeading';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
const { Sider } = Layout;
const { Title } = Typography;

const layoutCSS = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
type MenuItem = Required<MenuProps>['items'][number];
type contentDetails = {
  title: string;
  description: string;
  content: string;
  scenarioTitle?: string;
  pageHeading?: string;
  pageDescription?: string;
};

const KnowledgeBasePage = () => {
  const { knowledgeBaseCategory } = useParams();
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qnaItems, setQnaItems] = useState<any[]>([]);
  const [contentDetails, setContentDetails] = useState<contentDetails>();
  const [qna, setQna] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const location = useLocation();
  const locationFragment = location.pathname.split('/');
  const defaultSelectedMenu = `submenu-${knowledgeBaseCategory}`;
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);
  const mediaWrapper = {
    padding: '5px',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px',
    border: '1px solid #f0f0f0',
  };

  useEffect(() => {
    addBreadcrumbitem({
      breadCrumb: [
        { label: `Spoon Feed`, url: '' },
        { label: `Scenario`, url: location.pathname },
        contentDetails && {
          label: `${contentDetails?.scenarioTitle}`,
          url: location.pathname,
        },
        contentDetails && {
          label: `${contentDetails?.title}`,
          url: location.pathname,
        },
      ],
      headingInfo: {
        title: contentDetails?.pageHeading,
        description: contentDetails?.pageDescription,
      },
    });
  }, [contentDetails]);
  //console.log("content details in kb" , contentDetails)
  useEffect(() => {
    setIsLoading(true);

    getKnowledgeBaseCategories()
      .then((response: any) => {
        const content = response.answer;
        const items: MenuItem[] = response.answer.map(
          (item: any, index: number) => {
            const key = `submenu-${item.category_slug}`;
            return {
              key: key,
              label: (
                <Link to={`/knowledge-base/${item.category_slug}`}>
                  {item.category}
                </Link>
              ),
            };
          }
        );
        //setContentDetails({...content, scenarioTitle: response.data.attributes.title, pageHeading: response.data.attributes.page_heading, pageDescription: response.data.attributes.page_description});
        setMenuItems(items);
        setIsLoading(false);
        setSelectedMenu(`submenu-${knowledgeBaseCategory}`);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    knowledgeBaseCategory &&
      getKnowledgeBaseDetails(knowledgeBaseCategory)
        .then((response: any) => {
          setQna(response.answer);
          setIsLoading(false);
          setSelectedMenu(`submenu-${knowledgeBaseCategory}`);
        })
        .catch((error) => {
          setIsLoading(false);
        });
  }, [knowledgeBaseCategory]);
  const getQNAItem = () => {
    const items: any = qna.map((item: any, index: number) => {
      return {
        key: `qna-${index}`,
        label: item.question,
        category: item.category,
        children: (
          <div>
            <Markdown className="markdown-content" rehypePlugins={[rehypeRaw]}>
              {item.beautifiedAnswer}
            </Markdown>
            {item &&
              item.image &&
              item.image.length > 0 &&
              item.image.map((url: string, index: number) => {
                return (
                  <div>
                    <Image
                      key={`image-${item.id}`}
                      src={url}
                      className="mr-8"
                      alt=""
                      style={{
                        maxWidth: '300px',
                        height: 'auto',
                        border: 'solid 1px #ccc',
                        padding: '5px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                );
              })}
            {
              // moved the code to resolve flickering issue
              item &&
                item.video &&
                item.video.length > 0 &&
                item.video.map((video: { url: string; id: string }) => {
                  const { url, id } = video;
                  const uniqueVideoId = `${item.id}-${id}`;
                  return (
                    <div style={{ maxWidth: '900px', marginBottom: '15px' }}>
                      <VideoPlayer
                        id={uniqueVideoId}
                        videoURL={url}
                        playingVideoId={playingVideoId}
                        onVideoPlay={(videoId: string | null) => {
                          setPlayingVideoId(videoId);
                        }}
                      />
                    </div>
                  );
                })
            }
          </div>
        ),
      };
    });
    return items;
  };
  return (
    <>
      <Layout style={{ padding: '0', height: '100%' }}>
        <PageHeading heading="Knowledge Base" />
        <Layout style={{ padding: '0' }}>
          <Sider
            width={220}
            style={{
              backgroundColor: 'transparent',
              maxHeight: 'calc(100vh - 65px)',
              overflow: 'auto',
            }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    itemSelectedBg: '#001e5a',
                    itemSelectedColor: '#FFF',
                  },
                },
              }}
            >
              <Menu
                key={Math.random()}
                className="secondary-menu"
                defaultSelectedKeys={[selectedMenu]}
                defaultOpenKeys={[]}
                style={{
                  backgroundColor: 'transparent',
                  height: '100%',
                  borderRight: 0,
                }}
                items={menuItems}
              />
            </ConfigProvider>
          </Sider>

          <div
            className="main-content"
            style={{
              marginLeft: '20px',
              paddingRight: '10px',
              width: '100%',
              height: 'calc(100vh - 90px)',
              overflow: 'auto',
            }}
          >
            {isLoading ? (
              <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
            ) : (
              <>
                <Title
                  className="sub-heading knowledge-base-heading"
                  level={4}
                  style={{ marginTop: 0 }}
                >
                  {qna && qna[0] && qna[0].category}
                </Title>

                <Collapse
                  accordion={true}
                  bordered={false}
                  onChange={() => {
                    setPlayingVideoId(null);
                  }}
                  className="collapse-panel-custom"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusIcon
                        className="custom-icon hero-icon-12"
                        rotate={isActive ? 90 : 0}
                      />
                    ) : (
                      <PlusIcon
                        className="custom-icon hero-icon-12"
                        rotate={isActive ? 90 : 0}
                      />
                    )
                  }
                  size="middle"
                  items={getQNAItem()}
                  defaultActiveKey={['1']}
                />
              </>
            )}
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default KnowledgeBasePage;
