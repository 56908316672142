import React, { useEffect, useState } from 'react';
import {
  Table,
  Layout,
  Row,
  Col,
  Card,
  Button,
  Typography,
  Select,
  ConfigProvider,
  List,
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import DoughnutChart from './DoughnutChart';
import {
  getUsers,
  getCourseStatics,
  getAssessmentStatics,
  getDepartments,
  createInitialProgress,
} from '../services/ApiActions/adminAct';
import Loader from '../components/Layout/Loader';

const { Content } = Layout;
const { Text, Title } = Typography;

const AdminPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<
    string | undefined
  >();
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [courseStatics, setCourseStatics] = useState<any>(null);
  const [assessmentStatics, setAssessmentStatics] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const initRecord = await createInitialProgress();
        const [courseStaticsData, assessmentStaticsData] =
          await Promise.all<any>([getCourseStatics(), getAssessmentStatics()]);

        const courseData = [
          {
            title: 'Completed',
            value: courseStaticsData.data.completed,
            color: '#9B6F49',
          },
          {
            title: 'In Progress',
            value: courseStaticsData.data.in_progress,
            color: '#C3966E',
          },
          {
            title: 'Not Started',
            value: courseStaticsData.data.not_started,
            color: '#DBBB9E',
          },
        ];
        const assessmentData = [
          {
            title: 'Reviewed',
            value: assessmentStaticsData.data.reviewed,
            color: '#B5CAE7',
          },
          {
            title: 'Not Reviewed',
            value: assessmentStaticsData.data.not_reviewed,
            color: '#4566A9',
          },
        ];

        setCourseStatics(courseData);
        setAssessmentStatics(assessmentData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsUserLoading(true);
        const response: any = await getUsers(defaultDepartmentOption);
        setUsers(response.data);
        setIsUserLoading(false);
      } catch (error) {
        setIsUserLoading(false);
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [defaultDepartmentOption]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response: any = await getDepartments();
        const deptOptions = response.data.map((department: any) => ({
          label: department.attributes.name,
          value: department.attributes.name,
        }));
        deptOptions.unshift({ label: 'All', value: '' });
        setDepartmentOptions(deptOptions);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, []);

  const handleDepartmentChange = (department: string) => {
    setDefaultDepartmentOption(department);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Course Completed',
      dataIndex: 'course_data',
      key: 'course_data',
      render: (course_data: {
        complete_course: number;
        total_course: number;
      }) => (
        <span
          style={{
            background: '#F0E2D5',
            padding: '5px 8px',
            borderRadius: '15px',
          }}
        >
          {course_data.complete_course}/{course_data.total_course}
        </span>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id: string) => (
        <Link to={`/people/${user_id}/assessment`} style={{ display: 'flex' }}>
          <span>View Details &nbsp;</span>
          <ArrowTopRightOnSquareIcon className="custom-icon hero-icon-18" />
        </Link>
      ),
    },
  ];
  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <Layout style={{ padding: '10px 0' }}>
      <Content>
        <Row gutter={16}>
          <Col span={12} style={{ paddingRight: '12px' }}>
            <Card style={{ height: '100%' }}>
              {courseStatics && (
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Title level={3} style={{ marginTop: 0 }}>
                      Overall Course Completion Rate
                    </Title>
                    <ul className="custom-list">
                      <li className="color-completed">
                        <div>
                          Percentage of users who <b>completed</b> the course.
                        </div>
                      </li>
                      <li className="color-inprogress">
                        <div>
                          Percentage of users <b>in progress</b>.
                        </div>
                      </li>
                      <li className="color-not-started">
                        <div>
                          Percentage of users who <b>haven't started</b> the
                          course.
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div style={{ width: '160px', minHeight: '160px' }}>
                    <DoughnutChart data={courseStatics} />
                  </div>
                </div>
              )}
            </Card>
          </Col>
          <Col span={12} style={{ paddingRight: '12px' }}>
            <Card style={{ height: '100%' }}>
              {assessmentStatics && (
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Title level={3} style={{ marginTop: 0 }}>
                      Assessment Reviewed
                    </Title>
                    <ul className="custom-list">
                      <li className="color-reviewed">
                        <div>Reviewed.</div>
                      </li>
                      <li className="color-not-reviewed">
                        <div>Not Reviewed.</div>
                      </li>
                    </ul>
                  </div>
                  <div style={{ width: '160px', minHeight: '160px' }}>
                    <DoughnutChart data={assessmentStatics} />
                  </div>
                </div>
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '24px' }}>
          <Col span={24}>
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title level={3} style={{ margin: '0 0 0 5px', flexGrow: 1 }}>
                  Employee
                </Title>
                <Select
                  placeholder="Filter by Department"
                  defaultValue={defaultDepartmentOption}
                  onChange={handleDepartmentChange}
                  options={departmentOptions}
                  style={{ minWidth: '200px' }}
                />
              </div>
              {isUserLoading ? (
                <Loader
                  style={{ marginTop: '20%', justifyContent: 'center' }}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        cellFontSize: 16,
                      },
                    },
                  }}
                >
                  <Table
                    className="custom-table users-list"
                    bordered={false}
                    columns={columns}
                    dataSource={users}
                    pagination={false}
                  />
                </ConfigProvider>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AdminPage;
