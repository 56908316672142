import React from 'react';
import { Flex, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title } = Typography;
interface PageHeadingProps {
  heading: string;
  subHeading?: React.ReactElement;
  onBackClick?: () => any;
  rightContent?: React.ReactElement;
}
const PageHeading: React.FC<PageHeadingProps> = ({
  rightContent,
  heading,
  onBackClick,
  subHeading,
}) => (
  <>
    <Flex
      gap="small"
      justify="end"
      align="center"
      style={{ margin: '10px 0 16px' }}
    >
      <Flex style={{ flexGrow: 1 }} align="baseline">
        {onBackClick && (
          <ArrowLeftOutlined
            style={{ fontSize: '23px', marginRight: '10px' }}
            onClick={onBackClick}
          />
        )}
        <Title level={3} className="page-heading" style={{ margin: '0' }}>
          {heading}
        </Title>
        {subHeading}
        <img className="sparkle" src="/images/icons/sparkles.svg" />
      </Flex>
      {rightContent}
    </Flex>
  </>
);

export default PageHeading;
