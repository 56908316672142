import {
  Layout,
  Card,
  Select,
  List,
  Typography,
  Button,
  Spin,
  Alert,
  Form,
  Radio,
  Breadcrumb as AntBreadcrumb,
} from 'antd';
import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  quizByChapterId,
  userDetails,
  getAllModulesByCourseId,
  getChaptersByModuleId,
} from '../../services/ApiActions/adminAct';
import { getCourseById } from '../../services/ApiActions/lmsAct';
import {
  CheckOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import Loader from '../../components/Layout/Loader';
import ErrorMessageCard from '../../components/Error/ErrorMessageCard';

const { Sider, Content } = Layout;
const { Title, Text } = Typography;
interface option {
  id: number;
  option: string;
}

interface Quiz {
  option: string;
  options: option[];
  key: string;
  title: string;
  id: number;
  question: string;
  answer: string;
  is_correct: boolean;
  submitted_option: number;
  details: {
    question: string;
    answer: string;
  }[];
}
const MCQReviewPage = () => {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [loading, setLoading] = useState(false);
  const [isInitialing, setIsInitialing] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState<any>(null);
  const [chapterName, setChapterName] = useState<any>(null);
  const [courseName, setCourseName] = useState<any>(null);
  const [moduleName, setModuleName] = useState<any>(null);
  const [courseOptions, setCourseOptions] = useState<{
    options: any[];
    defaultOption: string;
  }>();
  const [chapters, setChapters] = useState<{
    options: any[];
    defaultOption: string;
  }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const user_id = searchParams.get('user_id');
  const chapter_id = searchParams.get('chapter_id');
  const course_id = searchParams.get('course_id');
  const module_id = searchParams.get('module_id');
  const return_url = searchParams.get('return_url');
  useEffect(() => {
    const initData = async () => {
      try {
        setIsInitialing(true);
        const response: any =
          course_id &&
          user_id &&
          (await getAllModulesByCourseId(course_id, user_id)); //Fix me
        const user: any = await userDetails(user_id as string);
        setUserData(user);
        if (response.status === 'success') {
          const options = response.data.modules.map((module: any) => {
            return { value: module.id, label: module.name };
          });
          //options.unshift({label: 'All', value: ''})
          //const defaultSelectedCouse = course_id && options.find((option: any) => option.value == course_id);
          const defaultSelectedCouse = module_id
            ? options.find((option: any) => option.value == module_id)
            : options[0];
          setModuleName(defaultSelectedCouse.label);
          setCourseOptions({
            options: options,
            ...(defaultSelectedCouse && {
              defaultOption: defaultSelectedCouse.value,
            }),
          });
        } else {
          console.error(
            'Failed to fetch courses:',
            response.message || 'Unknown error'
          );
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setIsInitialing(false);
      }
    };
    initData();
  }, []);
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response: any =
          course_id &&
          module_id &&
          (await getChaptersByModuleId(course_id, module_id));

        if (response.status === 'success') {
          if (!chapter_id) {
            const url = `${location.pathname}${location.search}&chapter_id=${response.data.chapters[0].id}`;
            navigate(url);
            return;
          }
          const chapterOptions =
            courseOptions &&
            response.data.chapters.find((opt: any) => opt.id == chapter_id);
          chapterOptions && setChapterName(chapterOptions.name);
          setCourseName(response.data.courseName);
          setChapters({ options: response.data.chapters, defaultOption: '' });
        } else {
          console.error(
            'Failed to fetch courses:',
            response.message || 'Unknown error'
          );
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, [courseOptions, module_id, chapter_id]);
  useEffect(() => {
    const fetchQuizzes = async () => {
      setLoading(true);
      setError(null);

      try {
        const response: any =
          chapter_id &&
          (await quizByChapterId(chapter_id as string, user_id as string));
        setQuizzes(response.data.quiz_response);
        console.log('data for ', response.data.quiz_response);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
        setError('Failed to fetch quizzes');
      } finally {
        setLoading(false);
      }
    };

    chapter_id && fetchQuizzes();
  }, [user_id, chapter_id]);

  const getOptionClassname = (
    is_correct: boolean,
    optionIndex: number,
    submitted_option: number
  ) => {
    let className = '';
    let text = '';

    if (submitted_option === optionIndex + 1) {
      className = is_correct ? 'correct-answer' : 'incorrect-answer';
      text = is_correct ? 'Correct' : 'Wrong';
    }

    return { className, text };
  };
  const handleChapterClick = (chapter: any) => {
    setChapterName(chapter.name);
    navigate(
      `/admin-quiz-review?course_id=${course_id}&module_id=${module_id}&chapter_id=${chapter.id}&user_id=${user_id}`
    );
  };
  const handleCourseChange = (moduleId: string) => {
    navigate(
      `/admin-quiz-review?course_id=${course_id}&module_id=${moduleId}&user_id=${user_id}`
    );
    // const selectedOption: any = courseOptions?.options.find(
    //   (option: any) => option.id == course
    // );
    //selectedOption && setCourseName(selectedOption.label);
    //setDefaultCourse(course);
  };
  if (isInitialing) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <div>
      <Layout>
        <Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: '23px', marginRight: '10px' }}
              onClick={() =>
                navigate(
                  return_url ? return_url : `/people/${user_id}/assessment`
                )
              }
            />
            <Typography.Title level={3} style={{ margin: 0 }}>
              {chapterName}
            </Typography.Title>
          </div>
          <AntBreadcrumb style={{ margin: '0 0 16px' }}>
            <AntBreadcrumb.Item>LMS</AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{courseName}</AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{moduleName}</AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{chapterName}</AntBreadcrumb.Item>
          </AntBreadcrumb>
          <Layout>
            <Sider style={{ backgroundColor: 'transparent' }} width={250}>
              <div>
                <Card>
                  <Title
                    level={4}
                    style={{ marginTop: 0, marginBottom: '10px' }}
                  >
                    {userData?.username}
                  </Title>
                  <p style={{ margin: 0, fontWeight: 500 }}>
                    {userData?.department}
                  </p>
                  <p style={{ margin: 0 }}>{userData?.designation}</p>
                  <p style={{ margin: 0 }}>{userData?.email}</p>
                </Card>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Card>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Review MCQ for Module :
                  </Title>
                  {courseOptions && (
                    <Select
                      placeholder="Select Course"
                      defaultValue={courseOptions.defaultOption}
                      onChange={handleCourseChange}
                      options={courseOptions.options}
                      style={{ width: '100%' }}
                    />
                  )}
                  <ul className="course-list">
                    {chapters &&
                      chapters.options.map((chapter: any) => {
                        return (
                          <li
                            className={chapter.id == chapter_id ? 'active' : ''}
                            onClick={() => handleChapterClick(chapter)}
                          >
                            {chapter.name}
                          </li>
                        );
                      })}
                  </ul>
                </Card>
              </div>
            </Sider>

            <Layout style={{ padding: '0' }}>
              <Content
                className="site-layout-background"
                style={{
                  padding: '0  0  0 10px',
                  margin: 0,
                  minHeight: '100%',
                }}
              >
                <Card style={{ minHeight: '100%' }}>
                  <Title level={4} style={{ marginTop: '0' }}>
                    Review MCQ
                  </Title>
                  {loading ? (
                    <Loader
                      style={{ marginTop: '20%', justifyContent: 'center' }}
                    />
                  ) : quizzes.length <= 0 ? (
                    <ErrorMessageCard message="To review this MCQ, the user must first attempt it." />
                  ) : (
                    quizzes.map((question, index) => {
                      const {
                        id,
                        question: questionText,
                        options,
                        submitted_option,
                        is_correct,
                        answer,
                      } = question;
                      const defaultOption = submitted_option;
                      // const answerIndex = answer - 1;

                      return (
                        <div key={id}>
                          <Text style={{ fontWeight: 600 }}> Questions:</Text>
                          <p>
                            {index + 1}. {questionText}
                          </p>
                          <div
                            className="quiz-question"
                            style={{
                              paddingLeft: '15px',
                              listStyleType: 'none',
                            }}
                          >
                            <Form.Item
                              style={{ marginBottom: '10px' }}
                              name={`question-${id}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Select your answer',
                                },
                              ]}
                            >
                              <Radio.Group defaultValue={defaultOption}>
                                {options.map((option, optionIndex) => {
                                  const answerClass = getOptionClassname(
                                    is_correct,
                                    optionIndex,
                                    submitted_option
                                  );
                                  return (
                                    <div
                                      key={option.id}
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <Radio
                                        className={answerClass.className}
                                        value={optionIndex + 1}
                                      >
                                        {option.option} {answerClass.text}
                                      </Radio>
                                      <br />
                                    </div>
                                  );
                                })}
                              </Radio.Group>
                            </Form.Item>
                            {typeof is_correct !== 'undefined' && (
                              <div style={{ marginBottom: '20px' }}>
                                {is_correct ? (
                                  <Text type="success">
                                    <CheckOutlined /> Correct!
                                  </Text>
                                ) : (
                                  <Text type="danger">
                                    <CloseOutlined /> Wrong!
                                  </Text>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </Card>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

export default MCQReviewPage;
