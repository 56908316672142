import React, { ChangeEvent, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {Col, Row, Button, Input, Layout, Space, Typography, Card, AutoComplete } from "antd";
import { SendOutlined } from "@ant-design/icons";
import BreadcrumbContext from '../components/Context';

const LearnPage = () => {
    const { addBreadcrumbitem } = useContext(BreadcrumbContext);
    const location = useLocation();
    useEffect(() => {
        addBreadcrumbitem([{ label: 'Learn', url: location.pathname }]);
    }, []);
    return (
        <div className='main-content'>
            <Layout.Content className="layout-content">
                <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                    <Col span={24} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                    <Col span={8} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>

                    <Col span={8} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                    <Col span={8} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                    <Col span={8} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                    <Col span={8} ><div style={{border: "solid 1px #ccc", padding: "10px"}}>col1</div></Col>
                </Row>
                
                </Card>
            </Layout.Content>
        </div>
    );
};

export default LearnPage;
