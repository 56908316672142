import { createContext, useState } from "react";
const BreadcrumbContext = createContext();
export function BreadcrumbProvider({children}){
    const [breadcrumbItem, setBreadcrumItem] = useState({breadCrumb: [], headerInfo: {}});
    const addBreadcrumbitem = (item) => {
        setBreadcrumItem(item);
    }
    return (
        <BreadcrumbContext.Provider value={{breadcrumbItem, addBreadcrumbitem}}>{children}</BreadcrumbContext.Provider>
    )
}
export default BreadcrumbContext